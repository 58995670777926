<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title> Dashboard</template>

        <template #buttons>
          <a-space>
            <a-button type="primary" @click="showRecentUpdates">
              <a-space> Recent Updates</a-space>
            </a-button>

            <a-tooltip title="Feedback">
              <a-button type="primary" danger ghost @click="addFeedback">
                <i class="ti ti-brand-hipchat ti-lg"></i>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>
      </page-header>

      <a-alert
        v-if="dashboardAlertMessages.birthdate"
        message="Happy Birthday!"
        type="info"
        showIcon
        class="mb-sm-1"
      >
        <template #icon>
          <i class="ti ti-cake"></i>
        </template>

        <template #description>
          Thank you for all your hard work during the past year. Wishing you a
          happy birthday and a great year to come!
        </template>
      </a-alert>

      <a-alert
        v-if="dashboardAlertMessages.anniversary"
        message="Happy Work Anniversary!"
        type="info"
        showIcon
        class="mb-sm-1"
      >
        <template #icon>
          <i class="ti ti-certificate-2"></i>
        </template>

        <template #description>
          Time flies when you're having fun and making a difference. Your
          dedication and hard work are truly appreciated. Here's to another year
          of success and growth!
        </template>
      </a-alert>

      <a-alert
        v-if="dashboardData['has_under_review_pe']"
        message="Performance Evaluation!"
        type="warning"
        showIcon
        class="mb-sm-1"
      >
        <template #icon>
          <i class="ti ti-signature"></i>
        </template>

        <template #description>
          Please check [<router-link
            :to="{ name: 'performance-evaluation' }"
            class="shortcut"
            >Click here</router-link
          >] and review your performance evalution.
        </template>
      </a-alert>

      <div class="gutter-row">
        <a-row type="flex" :gutter="[16, 16]">
          <a-col :sm="24" :md="isShowBirthdayComponent ? 16 : 24">
            <div class="shortcut-list">
              <router-link
                :to="{ name: isAdmin ? 'admin-order' : 'order' }"
                class="shortcut"
              >
                <a-badge :count="get_pending_order_count ?? 0">
                  <span class="shortcut-content">
                    <img
                      src="@/assets/product-order.svg"
                      alt="keyport"
                      class="img-fluid"
                    />
                    <span>Orders</span>
                  </span>
                </a-badge>
              </router-link>

              <router-link
                :to="{ name: isAdmin ? 'admin-expenses' : 'expenses' }"
                class="shortcut"
              >
                <a-badge :count="get_pending_expense_count ?? 0">
                  <span class="shortcut-content">
                    <img
                      src="@/assets/expense.svg"
                      alt="keyport"
                      class="img-fluid"
                    />
                    <span>Expense</span>
                  </span>
                </a-badge>
              </router-link>

              <router-link
                :to="{
                  name: isAdmin
                    ? 'admin-employee-time-tracks'
                    : 'employee-time-tracks',
                }"
                class="shortcut"
              >
                <a-badge :count="get_pending_time_tracking_count ?? 0">
                  <span class="shortcut-content">
                    <img
                      src="@/assets/log-hours.svg"
                      alt="keyport"
                      class="img-fluid"
                    />
                    <span>Timesheet</span>
                  </span>
                </a-badge>
              </router-link>

              <router-link
                :to="{ name: isAdmin ? 'admin.case-report' : 'case-report' }"
                class="shortcut"
              >
                <a-badge :count="get_pending_case_reporting_count ?? 0">
                  <span class="shortcut-content">
                    <img
                      src="@/assets/add-case.svg"
                      alt="keyport"
                      class="img-fluid"
                    />
                    <span>Case Reporting</span>
                  </span>
                </a-badge>
              </router-link>

              <router-link
                v-if="
                  isAdmin ||
                  ($comman.canAccess('admin-credentialing-approvals') &&
                    profileStatus == 1)
                "
                :to="{ name: 'admin-approvals' }"
                class="shortcut"
              >
                <a-badge
                  :count="get_total_pending_credentialing_approvals ?? 0"
                >
                  <span class="shortcut-content">
                    <img
                      src="@/assets/certificate-2.svg"
                      alt="keyport"
                      class="img-fluid"
                    />
                    <span>Credentialing Approvals</span>
                  </span>
                </a-badge>
              </router-link>

              <a
                href="https://workforcenow.adp.com/"
                target="_blank"
                class="shortcut"
              >
                <a-badge>
                  <span class="shortcut-content">
                    <img
                      src="@/assets/pto-request.svg"
                      alt="keyport"
                      class="img-fluid"
                    />
                    <span>Time Off</span>
                  </span>
                </a-badge>
              </a>

              <a
                href="https://workforcenow.adp.com/"
                target="_blank"
                class="shortcut"
              >
                <a-badge>
                  <span class="shortcut-content">
                    <img
                      src="@/assets/payroll.svg"
                      alt="keyport"
                      class="img-fluid"
                    />
                    <span>ADP Login</span>
                  </span>
                </a-badge>
              </a>

              <router-link
                :to="{ name: 'employee-benefits-show' }"
                class="shortcut"
              >
                <a-badge>
                  <span class="shortcut-content">
                    <img
                      src="@/assets/healthbenefits.svg"
                      alt="keyport"
                      class="img-fluid"
                    />
                    <span>Benefits</span>
                  </span>
                </a-badge>
              </router-link>

              <a
                href="https://webmail.emailsrvr.com/"
                target="_blank"
                class="shortcut"
              >
                <a-badge>
                  <span class="shortcut-content">
                    <img
                      src="@/assets/webmails.svg"
                      alt="keyport"
                      class="img-fluid"
                    />
                    <span>Email Login</span>
                  </span>
                </a-badge>
              </a>
            </div>
          </a-col>

          <a-col v-if="isShowBirthdayComponent" :sm="24" :md="8">
            <birthdate />
          </a-col>
        </a-row>

        <a-row type="flex" :gutter="[16, 16]">
          <template v-if="isAdmin">
            <a-col :xs="24" :sm="12" :lg="8">
              <a-card>
                <a-statistic
                  title="Total Team Members"
                  :value="dashboardData['total_employee_count']"
                />
              </a-card>
            </a-col>

            <a-col :xs="24" :sm="12" :lg="8">
              <a-card>
                <i class="icon icon-apps"></i>
                <a-statistic
                  title="This Month Case Reporting"
                  :value="dashboardData['total_this_month_case_report_count']"
                />
              </a-card>
            </a-col>

            <a-col :xs="24" :sm="12" :lg="8">
              <a-card>
                <a-statistic
                  title="This Month Orders"
                  :value="dashboardData['total_this_month_order_count']"
                />
              </a-card>
            </a-col>
          </template>

          <template v-if="isAdmin || user?.role_id == 3">
            <a-col
              :xs="24"
              :sm="12"
            >   
              <a-card v-if="loading">
                <a-skeleton v-if="loading" active />
              </a-card>
              <a-card v-else>
                <router-link
                  :to="{
                    name: isAdmin
                      ? 'admin-performance-evaluation'
                      : 'manager.performance-evaluation',
                    query: { status: 3 },
                  }"
                >
                  <a-statistic
                    title="Performance Evalution"
                    :value="dashboardData['pending_sign_pe_count']"
                  >
                    <template #prefix>
                      <span class="alert-warning-pulse">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="16"
                          height="16"
                          fill="rgba(235,0,0,1)"
                        >
                          <path
                            d="M4.00001 20V14C4.00001 9.58172 7.58173 6 12 6C16.4183 6 20 9.58172 20 14V20H21V22H3.00001V20H4.00001ZM6.00001 14H8.00001C8.00001 11.7909 9.79087 10 12 10V8C8.6863 8 6.00001 10.6863 6.00001 14ZM11 2H13V5H11V2ZM19.7782 4.80761L21.1924 6.22183L19.0711 8.34315L17.6569 6.92893L19.7782 4.80761ZM2.80762 6.22183L4.22183 4.80761L6.34315 6.92893L4.92894 8.34315L2.80762 6.22183Z"
                          ></path>
                        </svg>
                      </span>
                    </template>
                  </a-statistic>
                </router-link>
              </a-card>
            </a-col>

            <a-col :xs="24" :sm="12">
              <a-card v-if="loading">
                <a-skeleton v-if="loading" active />
              </a-card>
              <a-card v-else>
                <router-link
                  :to="{
                    name: 'report.auto-qi-report',
                  }"
                >
                  <a-statistic
                    title="Pending Auto QI Report"
                    :value="
                      dashboardData['pending_hospital_qi_notification_count']
                    "
                  >
                    <template #prefix>
                      <span class="alert-warning-pulse">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="16"
                          height="16"
                          fill="rgba(235,0,0,1)"
                        >
                          <path
                            d="M4.00001 20V14C4.00001 9.58172 7.58173 6 12 6C16.4183 6 20 9.58172 20 14V20H21V22H3.00001V20H4.00001ZM6.00001 14H8.00001C8.00001 11.7909 9.79087 10 12 10V8C8.6863 8 6.00001 10.6863 6.00001 14ZM11 2H13V5H11V2ZM19.7782 4.80761L21.1924 6.22183L19.0711 8.34315L17.6569 6.92893L19.7782 4.80761ZM2.80762 6.22183L4.22183 4.80761L6.34315 6.92893L4.92894 8.34315L2.80762 6.22183Z"
                          ></path>
                        </svg>
                      </span>
                    </template>
                  </a-statistic>
                </router-link>
              </a-card>
            </a-col>
          </template>

          <a-col :xs="24">
            <DueAndUpcomingPe :paginationSettings="paginationSettings" />
          </a-col>

          <template v-if="isAdmin">
            <template
              v-if="
                dashboardData['pending_for_approval_candidates']?.length > 0 ||
                dashboardData['hospital_annual_escalator_expiring_soon']
                  ?.length > 0
              "
            >
              <a-col :xs="24" :lg="12">
                <PendingForApprovalCandidate
                  :data="dashboardData['pending_for_approval_candidates']"
                  :paginationSettings="paginationSettings"
                  @update="
                    (data) =>
                      (dashboardData['pending_for_approval_candidates'] = data)
                  "
                />
              </a-col>

              <a-col :xs="24" :lg="12">
                <HospitalAnnualEscalator
                  :data="
                    dashboardData['hospital_annual_escalator_expiring_soon']
                  "
                  :paginationSettings="paginationSettings"
                />
              </a-col>
            </template>

            <a-col :xs="24" :lg="12">
              <a-card
                size="small"
                :bodyStyle="{ height: '300px', overflowY: 'scroll' }"
              >
                <template #title>
                  <a-space>
                    Credentialing Status
                    <a-tooltip placement="top">
                      <template #title> Expiring in upcoming 30 days</template>
                      <a-button size="small" type="link">
                        <i class="ti ti-info-circle ti-lg"> </i>
                      </a-button>
                    </a-tooltip>
                  </a-space>
                </template>
                <a-table
                  :columns="certificateExpiringSoonColumnsForAdmin"
                  :rowKey="(record, index) => record.employee_id"
                  :dataSource="certificateExpiringSoon"
                  :pagination="paginationSettings"
                  size="small"
                  :scroll="{ x: true, y: 0 }"
                >
                  <template #employee_name="{ record }">
                    <router-link
                      :to="{
                        name: 'show-employee',
                        params: { id: record.employee_id },
                        query: { tab: 'certificates' },
                      }"
                    >
                      {{ record.employee_name }}
                    </router-link>
                  </template>
                </a-table>
              </a-card>
            </a-col>

            <a-col :xs="24" :lg="12">
              <a-card
                title="Hospital Contracts Due/Expired"
                size="small"
                :bodyStyle="{ height: '300px', overflowY: 'scroll' }"
              >
                <a-table
                  :columns="hospitalContractExpiringSoonColumnsForAdmin"
                  :rowKey="(record, index) => record.hospital_id"
                  :dataSource="dashboardData['hospital_contract_expiring_soon']"
                  :pagination="paginationSettings"
                  size="small"
                  :scroll="{ x: true, y: 0 }"
                >
                  <template #hospital_name="{ record }">
                    <router-link
                      :to="{
                        name: 'hospital-details',
                        params: { id: record.hospital_id },
                      }"
                    >
                      {{ record.hospital?.name ?? "N/A" }}
                    </router-link>
                  </template>

                  <template #attachment="{ record }">
                    <template
                      v-for="(attachment, index) in record.attachments"
                      :key="attachment.id"
                    >
                      <a
                        href="#"
                        @click.prevent="
                          $comman.downloadItem({
                            url: attachment.full_file_path,
                            name: attachment.file_name,
                          })
                        "
                      >
                        {{ index + 1 }}. Attachment
                      </a>
                      <br />
                    </template>
                  </template>
                  <template #expiry_date="{ record }">
                    {{ $customDate.ll(record.expiry_date) }}
                    <a-tag
                      color="red"
                      style="margin-left: 5px"
                      v-if="moment().format('YYYY-MM-DD') >= record.expiry_date"
                    >
                      Expired
                    </a-tag>
                  </template>
                </a-table>
              </a-card>
            </a-col>
          </template>

          <template v-else>
            <a-col
              v-if="
                dashboardData['certificate_expiring_soon'] &&
                dashboardData['certificate_expiring_soon'].length > 0
              "
              :xs="24"
            >
              <a-card title="Credentialing Status" size="small">
                <a-table
                  :columns="certificateExpiringSoonColumnsForEmployee"
                  :rowKey="(record, index) => record.employee_id"
                  :dataSource="dashboardData['certificate_expiring_soon']"
                  :pagination="paginationSettings"
                  size="small"
                  :scroll="{ x: true, y: 0 }"
                >
                  <template #is_expired="{ record }">
                    <a-tag
                      color="red"
                      v-if="moment().diff(record.expiry_date, 'days') > 0"
                    >
                      Yes
                    </a-tag>
                    <a-tag color="green" v-else> No</a-tag>
                  </template>
                </a-table>
              </a-card>
            </a-col>
          </template>
        </a-row>
      </div>

      <div class="gutter-row">
        <template v-if="isAdmin">
          <a-row type="flex" :gutter="[16, 16]">
            <a-col :xs="24" :lg="24">
              <a-spin :spinning="serviceByStatesChartLoading">
                <a-card title="Services by State" size="small">
                  <template #extra>
                    <a-month-picker
                      v-model:value="serviceByStatesChartMonth"
                      placeholder="Select month"
                      @change="getServiceByStatesChartData"
                    />
                  </template>

                  <div
                    class="dashboard-chart"
                    ref="serviceByStatesByMonth"
                  ></div>
                </a-card>
              </a-spin>
            </a-col>
          </a-row>
        </template>

        <template v-else>
          <a-row
            v-if="
              employee.is_show_time_tracking_on_dashboard ||
              employee.is_show_case_reporting_on_dashboard
            "
            type="flex"
            :gutter="[16, 16]"
          >
            <a-col
              v-if="employee.is_show_time_tracking_on_dashboard"
              :xs="24"
              :lg="24"
            >
              <dashboard-time-tracking />
            </a-col>

            <a-col
              v-if="employee.is_show_case_reporting_on_dashboard"
              :xs="24"
              :lg="24"
            >
              <dashboard-case-reporting />
            </a-col>
          </a-row>
        </template>

        <a-row type="flex" :gutter="[16, 16]">
          <a-col :xs="24">
            <a-spin :spinning="caseReportChartLoading">
              <a-card title="Case Reports" size="small">
                <a-space size="large" direction="vertical" style="width: 100%">
                  <a-select
                    v-model:value="caseReportChartHospital"
                    placeholder="Select hospital"
                    optionFilterProp="label"
                    showSearch
                    allowClear
                    @change="getCaseReportChartData"
                  >
                    <a-select-option
                      v-for="option in hospitals"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>

                  <div class="dashboard-chart" ref="caseReportByMonth"></div>
                </a-space>
              </a-card>
            </a-spin>
          </a-col>

          <a-col :xs="24">
            <a-spin :spinning="expenseChartLoading">
              <a-card title="Expense Reimbursement by Month" size="small">
                <a-space size="large" direction="vertical" style="width: 100%">
                  <a-month-picker
                    v-model:value="expenseChartMonth"
                    placeholder="Select month"
                    style="width: 100%"
                    @change="getExpenseChartData"
                  />

                  <div class="dashboard-chart" ref="expenseByMonth"></div>
                </a-space>
              </a-card>
            </a-spin>
          </a-col>

          <template v-if="isAdmin">
            <a-col :xs="8">
              <a-spin :spinning="empGenderChartLoading">
                <a-card title="Employee Gender Stats" size="small">
                  <div ref="employeeGender" class="dashboard-chart"></div>
                </a-card>
              </a-spin>
            </a-col>

            <a-col :xs="8">
              <a-spin :spinning="empVConChartLoading">
                <a-card title="Employees vs Contractors" size="small">
                  <div ref="employeeVsContractor" class="dashboard-chart"></div>
                </a-card>
              </a-spin>
            </a-col>

            <a-col :xs="8">
              <a-spin :spinning="empPositionChartLoading">
                <a-card title="Employee Position Stats" size="small">
                  <div class="dashboard-chart" ref="employeePosition"></div>
                </a-card>
              </a-spin>
            </a-col>

            <a-col :span="24">
              <PerStateHospitalCount />
            </a-col>
          </template>
        </a-row>
      </div>
    </a-layout-content>

    <a-modal
      v-model:visible="showAnnouncement"
      :bodyStyle="{ maxHeight: '80vh', overflowY: 'scroll' }"
      :closable="true"
      :footer="null"
      size="small"
      :keyboard="true"
      :maskClosable="true"
      centered
      width="800px"
      title="Announcements"
    >
      <div
        v-for="(announcement, idx) in announcements"
        :key="announcement.id"
        style="width: 100%"
      >
        <a-space :size="5" direction="vertical" style="width: 100%">
          <a-typography-title :level="4">
            {{ announcement.title }}
          </a-typography-title>

          <img
            v-if="announcement.images.length === 1"
            :src="announcement.images[0].full_file_path"
            class="mb-sm-1"
            style="object-fit: cover; width: 100%; aspect-ratio: 16/4"
          />

          <Splide
            :options="{
              type: 'loop',
              rewind: true,
              pagination: true,
              autoplay: true,
            }"
            class="mb-sm-1"
            v-else-if="announcement.images.length > 1"
          >
            <SplideSlide v-for="(image, idx) in announcement.images" :key="idx">
              <img
                :src="image.full_file_path"
                style="object-fit: cover; width: 100%; aspect-ratio: 16/4"
              />
            </SplideSlide>
          </Splide>

          <p v-html="announcement.content" />

          <a-divider
            v-if="idx !== announcements.length - 1"
            style="
              width: 100%;
              height: 1px;
              background-color: rgba(0, 0, 0, 0.2);
            "
          />
        </a-space>
      </div>
    </a-modal>

    <!--    <a-modal
      v-model:visible="maintenanceModal"
      :maskClosable="false"
      :footer="false"
      :keyboard="false"
    >
      <a-space direction="vertical" align="center" style="width: 100%">
        <a-typography-title type="warning" :level="3" class="mb-sm-0">
          <exclamation-circle-outlined />
        </a-typography-title>

        <a-space :size="1" direction="vertical" align="center">
          <a-typography-title :level="3"> Announcement </a-typography-title>
          <a-typography-text class="font-16">
            <div v-html="maintenance.message"></div>
          </a-typography-text>
        </a-space>
      </a-space>
    </a-modal>-->
  </div>
</template>

<script>
import PerStateHospitalCount from "@/components/dashboard/PerStateHospitalCount.vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Column, Heatmap, Pie } from "@antv/g2plot";
import { Splide } from "@splidejs/vue-splide";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import birthdate from "../components/dashboard/birthdate.vue";
import DashboardCaseReporting from "../components/dashboard/caseReporting.vue";
import DueAndUpcomingPe from "../components/dashboard/dueAndUpcomingPe.vue";
import HospitalAnnualEscalator from "../components/dashboard/hospitalAnnualEscalator.vue";
import PendingForApprovalCandidate from "../components/dashboard/pendingForApprovalCandidate.vue";
import DashboardTimeTracking from "../components/dashboard/timeTracking.vue";
import { commonService } from "../services";

export default {
  components: {
    PerStateHospitalCount,
    Splide,
    DashboardTimeTracking,
    DashboardCaseReporting,
    ExclamationCircleOutlined,
    birthdate,
    HospitalAnnualEscalator,
    PendingForApprovalCandidate,
    DueAndUpcomingPe,
  },

  data() {
    return {
      loading: true,
      params: {},
      moment,

      showAnnouncement: false,
      announcements: [],

      dashboardData: {
        total_employee_count: 0,
        total_this_month_case_report_count: 0,
        total_this_month_order_count: 0,
        hospital_contract_expiring_soon: [],
        certificate_expiring_soon: [],
      },
      hospitalContractExpiringSoonColumnsForAdmin: [
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          slots: {
            customRender: "hospital_name",
          },
        },
        {
          title: "Attachment",
          dataIndex: "attachment",
          slots: {
            customRender: "attachment",
          },
        },
        {
          title: "Expiration Date",
          dataIndex: "expiry_date",
          slots: {
            customRender: "expiry_date",
          },
        },
      ],
      certificateExpiringSoonColumnsForAdmin: [
        {
          title: "Employee",
          dataIndex: "employee_name",
          key: "employee_name",
          sorter: (a, b) => (a.employee_name > b.employee_name ? 1 : -1),
          defaultSortOrder: "ascend",
          slots: {
            customRender: "employee_name",
          },
        },
        {
          title: "Certificates",
          dataIndex: "certificate_count",
          key: "certificate_count",
          sorter: (a, b) => a.certificate_count - b.certificate_count,
        },
      ],
      certificateExpiringSoonColumnsForEmployee: [
        {
          title: "Certificate",
          dataIndex: "certificate",
          key: "certificate",
          sorter: (a, b) =>
            a.certification_type?.name > b.certification_type?.name ? 1 : -1,
          customRender: ({ record }) =>
            record.certification_type?.name ?? "N/A",
        },
        {
          title: "Uploaded On",
          dataIndex: "created_at",
          key: "created_at",
          sorter: (a, b) =>
            moment(b.created_at).isBefore(moment(a.created_at)) ? 1 : -1,
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Expiration Date",
          dataIndex: "expiry_date",
          key: "expiry_date",
          sorter: (a, b) =>
            moment(b.expiry_date).isBefore(moment(a.expiry_date)) ? 1 : -1,
          defaultSortOrder: "ascend",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Expired?",
          dataIndex: "is_expired",
          key: "is_expired",
          slots: {
            customRender: "is_expired",
          },
        },
      ],
      paginationSettings: {
        pageSize: 5,
      },

      // CHARTS DATA
      // CASE REPORTS
      hospitals: [],
      caseReportChartLoading: true,
      caseReportChartData: [],
      caseReportChart: null,
      caseReportChartHospital: undefined,
      caseReportChartConfig: {
        isGroup: true,
        columnStyle: {
          radius: [5, 5, 0, 0],
        },
        xField: "month",
        yField: "value",
        seriesField: "report_type",
        // dodgePadding: 2,
        // intervalPadding: 50,
        label: {
          position: "top",
          rotate: 11,
          offsetX: 10,
          offsetY: 5,
          autoRotate: false,
        },
        legend: {
          layout: "horizontal",
          position: "top",
        },
      },

      // EXPENSES
      expenseChartLoading: true,
      expenseChartData: [],
      expenseChart: null,
      expenseChartMonth: null,
      expenseChartConfig: {
        isGroup: true,
        columnStyle: {
          radius: [5, 5, 0, 0],
        },
        xField: "week",
        yField: "value",
        seriesField: "expense_type",
        // dodgePadding: 2,
        // intervalPadding: 50,
        label: {
          position: "top",
          rotate: 11,
          offsetX: 15,
          offsetY: 5,
          autoRotate: false,
          formatter: function (e) {
            return parseFloat(e.value)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        tooltip: {
          formatter: (e) => {
            return {
              name: e.expense_type,
              value: parseFloat(e.value)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            };
          },
        },
        legend: {
          layout: "horizontal",
          position: "top",
        },
      },

      // SERVICE BY STATES
      serviceByStatesChartLoading: true,
      serviceByStatesChartData: [],
      serviceByStatesChart: null,
      serviceByStatesChartMonth: null,
      serviceByStatesChartConfig: {
        xField: "state",
        yField: "service_type",
        colorField: "value",
        sizeField: "value",
        shape: "circle",
        color: ["#dddddd", "#9ec8e0", "#5fa4cd", "#2e7ab6", "#114d90"],
        label: {
          style: {
            fill: "#fff",
            shadowBlur: 2,
            shadowColor: "rgba(0, 0, 0, .45)",
          },
        },
      },

      // EMPLOYEE VS CONTRACTOR PIE CHART
      empVConChartLoading: true,
      empVConChartData: [],
      empVConChart: null,
      empVConChartConfig: {
        appendPadding: 10,
        angleField: "value",
        colorField: "type",
        radius: 0.9,
        legend: {
          position: "top",
          maxRow: 4,
          flipPage: false,
          itemValue: {
            formatter: (text) => {
              let value =
                this.empVConChartData.find((e) => e.type == text)?.value ?? "0";
              return `(${value})`;
            },
            style: {
              opacity: 0.65,
            },
          },
        },
        label: {
          type: "outer",
          offset: "10%",
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: "center",
          },
        },
        interactions: [{ type: "element-active" }],
      },

      // EMPLOYEE GENDER PIE CHART
      empGenderChartLoading: true,
      empGenderChartData: [],
      empGenderChart: null,
      empGenderChartConfig: {
        appendPadding: 10,
        angleField: "value",
        colorField: "type",
        radius: 0.9,
        legend: {
          position: "top",
          maxRow: 4,
          flipPage: false,
          itemValue: {
            formatter: (text) => {
              let value =
                this.empGenderChartData.find((e) => e.type == text)?.value ??
                "0";
              return `(${value})`;
            },
            style: {
              opacity: 0.65,
            },
          },
        },
        label: {
          type: "inner",
          offset: "10%",
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: "center",
          },
        },
        interactions: [{ type: "element-active" }],
      },

      // EMPLOYEE POSITION PIE CHART
      empPositionChartLoading: true,
      empPositionChartData: [],
      empPositionChart: null,
      empPositionChartConfig: {
        appendPadding: 10,
        angleField: "value",
        colorField: "type",
        radius: 0.9,
        legend: {
          position: "top",
          maxRow: 4,
          flipPage: false,
          itemValue: {
            formatter: (text) => {
              let value =
                this.empPositionChartData.find((e) => e.type == text)?.value ??
                "0";
              return `(${value})`;
            },
            style: {
              opacity: 0.65,
            },
          },
        },
        label: {
          type: "outer",
          offset: "10%",
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: "center",
          },
        },
        interactions: [{ type: "element-active" }],
      },

      // MAINTENANCE
      // maintenanceModal: false,
      // maintenance: {
      //   message: null,
      //   end_date_time: null,
      // },
    };
  },

  mounted() {
    this.getData();
    this.getHospitals();
    this.getCaseReportChartData();
    this.getExpenseChartData();
    // this.fetchSetting();

    if (this.isAdmin) {
      this.getServiceByStatesChartData();
      this.getEmployeeChartData();
    }

    this.fetchAnnouncements().then(() => {
      if (this.announcements.length > 0) {
        this.showAnnouncement = true;
      }
    });
  },

  computed: {
    ...mapGetters("auth", [
      "user",
      "isAdmin",
      "employee",
      "birthdayNames",
      "workAnniversaryNames",
      "dashboardAlertMessages",
      "profileStatus",
    ]),
    ...mapGetters("navbarCount", [
      "get_pending_expense_count",
      "get_pending_time_tracking_count",
      "get_pending_order_count",
      "get_pending_case_reporting_count",
      "get_total_pending_credentialing_approvals",
    ]),

    certificateExpiringSoon() {
      let res = [];
      res = this.dashboardData["certificate_expiring_soon"].reduce(
        (pre, curr) => {
          let employee = pre.find(
            (item) => item.employee_id == curr.employee_id
          );
          if (!employee) {
            pre.push({
              employee_id: curr.employee_id,
              employee_name: curr.employee.full_name,
              certificate_count: 1,
            });
            return pre;
          }
          employee.certificate_count += 1;
          return pre;
        },
        []
      );
      return res;
    },

    isShowBirthdayComponent() {
      return (
        Object.values(this.birthdayNames).length > 0 ||
        Object.values(this.workAnniversaryNames).length > 0
      );
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    fetchAnnouncements() {
      return commonService.get(this.$constants.getNotifications).then((res) => {
        this.announcements = res.data;
      });
    },

    getData() {
      commonService
        .get(this.$constants.getDashboardUrl)
        .then((res) => {
          this.dashboardData = res.data;
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.loading = false));
    },

    getHospitals() {
      commonService.get(this.$constants.getHospitalsUrl).then((res) => {
        this.hospitals = res.data;
      });
    },

    getCaseReportChartData() {
      this.caseReportChartLoading = true;
      commonService
        .get(this.$constants.getDashboardCaseReportChartDataUrl, {
          hospital_id: this.caseReportChartHospital,
        })
        .then(async (res) => {
          this.caseReportChartLoading = false;
          this.caseReportChartData = res.data;
          let amounts = this.caseReportChartData.map((e) => e.value);
          let maxAmount = amounts.length > 0 ? Math.max(...amounts) : 0;
          let max = Math.round(maxAmount + maxAmount * 0.1);
          let obj = {
            data: this.caseReportChartData,
            ...this.caseReportChartConfig,
            yAxis: {
              max,
            },
          };
          if (this.caseReportChart) {
            this.caseReportChart.update(obj);
          } else {
            this.caseReportChart = new Column(
              this.$refs["caseReportByMonth"],
              obj
            );
            await this.$nextTick(() => {
              this.caseReportChart.render();
            });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.caseReportChartLoading = false));
    },

    getExpenseChartData() {
      this.expenseChartLoading = true;
      commonService
        .get(this.$constants.getDashboardExpenseChartDataUrl, {
          month: this.expenseChartMonth
            ? this.expenseChartMonth.format("YYYY-MM-01")
            : undefined,
        })
        .then(async (res) => {
          this.expenseChartLoading = false;
          this.expenseChartData = res.data;
          let amounts = this.expenseChartData.map((e) => e.value);
          let maxAmount = amounts.length > 0 ? Math.max(...amounts) : 0;
          let max = Math.round(maxAmount + maxAmount * 0.2);
          let obj = {
            data: this.expenseChartData,
            ...this.expenseChartConfig,
            yAxis: {
              max,
            },
          };
          if (this.expenseChart) {
            this.expenseChart.update(obj);
          } else {
            this.expenseChart = new Column(this.$refs["expenseByMonth"], obj);
            await this.$nextTick(() => {
              this.expenseChart.render();
            });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => (this.expenseChartLoading = false));
    },

    getServiceByStatesChartData() {
      this.serviceByStatesChartLoading = true;
      commonService
        .get(this.$constants.getDashboardServiceByStatesChartDataUrl, {
          month: this.serviceByStatesChartMonth
            ? this.serviceByStatesChartMonth.format("YYYY-MM-01")
            : undefined,
        })
        .then(async (res) => {
          this.serviceByStatesChartLoading = false;
          this.serviceByStatesChartData = res.data;
          if (this.serviceByStatesChart) {
            this.serviceByStatesChart.changeData(this.serviceByStatesChartData);
          } else {
            this.serviceByStatesChart = new Heatmap(
              this.$refs["serviceByStatesByMonth"],
              {
                data: this.serviceByStatesChartData,
                ...this.serviceByStatesChartConfig,
              }
            );
            await this.$nextTick(() => {
              this.serviceByStatesChart.render();
            });
          }
        })
        .catch(({ stack, message }) => {
          // this.$message.error(err);
          // console.info({
          //   title: "Antdv G2Plot Error",
          //   message,
          // });
          // this.$message.error(message);
        })
        .finally(() => (this.serviceByStatesChartLoading = false));
    },

    async getEmployeeChartData() {
      this.empVConChartLoading = true;
      this.empGenderChartLoading = true;
      this.empPositionChartLoading = true;

      commonService
        .get(this.$constants.getDashboardEmployeeChartDataUrl)
        .then(async (res) => {
          this.empVConChartLoading = false;
          this.empVConChartData = res.data.employee_vs_contractor;
          if (this.empVConChart) {
            this.empVConChart.changeData(this.empVConChartData);
          } else {
            this.empVConChart = new Pie(this.$refs["employeeVsContractor"], {
              data: this.empVConChartData,
              ...this.empVConChartConfig,
            });
            await this.$nextTick(() => {
              this.empVConChart.render();
            });
          }

          this.empGenderChartLoading = false;
          this.empGenderChartData = res.data.employee_gender;
          if (this.empGenderChart) {
            this.empGenderChart.changeData(this.empGenderChartData);
          } else {
            this.empGenderChart = new Pie(this.$refs["employeeGender"], {
              data: this.empGenderChartData,
              ...this.empGenderChartConfig,
            });
            await this.$nextTick(() => {
              this.empGenderChart.render();
            });
          }

          this.empPositionChartLoading = false;
          this.empPositionChartData = res.data.employee_position;
          if (this.empPositionChart) {
            this.empPositionChart.changeData(this.empPositionChartData);
          } else {
            this.empPositionChart = new Pie(this.$refs["employeePosition"], {
              data: this.empPositionChartData,
              ...this.empPositionChartConfig,
            });
            await this.$nextTick(() => {
              this.empPositionChart.render();
            });
          }
        })
        .catch(({ stack, message }) => {
          // this.$message.error(err);
          // console.info({
          //   title: "Antdv G2Plot Error",
          //   message,
          // });
          // this.$message.error(message);
        })
        .finally(() => {
          this.empVConChartLoading = false;
          this.empGenderChartLoading = false;
          this.empPositionChartLoading = false;
        });
    },

    showRecentUpdates() {
      this.open({
        title: "Recent Updates",
        path: "recentUpdates",
      });
    },

    addFeedback() {
      this.open({
        title: "Feedback",
        path: "feedback",
      });
    },

    showEmployeeBenefit() {
      this.open({
        title: "Employee Benefits",
        path: "employeeBenefitsShow",
      });
    },

    // fetchSetting() {
    //   commonService
    //     .store(this.$constants.getSettingUrl, {
    //       key: ["maintenance_message", "maintenance_end_date_time"],
    //     })
    //     .then((res) => {
    //       this.maintenance.message = res.data.maintenance_message;
    //       this.maintenance.end_date_time = res.data.maintenance_end_date_time
    //         ? moment(res.data.maintenance_end_date_time).unix()
    //         : null;
    //       let currentTime = moment().unix();
    //
    //       if (
    //         this.maintenance.message &&
    //         this.maintenance.end_date_time &&
    //         this.maintenance.end_date_time >= currentTime
    //       ) {
    //         this.maintenanceModal = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.$message.error(err.message);
    //     });
    // },
  },
};
</script>

<style lang="less">
.gutter-row .ant-row {
  margin-bottom: 16px;
}

.shortcut-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  .shortcut {
    text-align: center;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
    transition: all 300ms ease-in-out;

    .ant-badge {
      display: block;
    }

    .shortcut-content {
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .ti {
        margin-bottom: 8px;
      }

      img {
        max-width: 64px;
        margin-bottom: 15px;
      }
    }

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border: 1px solid #1890ff;
      background-color: #1890ff;

      .shortcut-content {
        color: #fff;

        .ti {
          color: #fff;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

/*.dashboard-chart {
  :not(div:last-child) {
    display: none;
  }
}*/
</style>

<template>
  <a-form :model="model" :rules="rules" layout="vertical" @finish="onSubmit">
    <a-form-item
      ref="from_hospital_id"
      label="Hospital"
      name="from_hospital_id"
    >
      <a-select
        v-model:value="model.from_hospital_id"
        placeholder="Select One..."
        optionFilterProp="label"
        showSearch
      >
        <a-select-option
          v-for="hospital in hospitals"
          :key="hospital.id"
          :label="hospital.name"
          :value="hospital.id"
        >
          {{ hospital.name }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item v-if="false" ref="qty" label="Initial Qty" name="qty">
      <a-input-number
        v-model:value="model.qty"
        placeholder="Enter here..."
        type="number"
        step="0.01"
      />
    </a-form-item>

    <side-drawer-buttons name="Submit" />
  </a-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  name: "importFromHospital",

  emit: ["callback"],

  data() {
    return {
      excelExportLoading: false,
      hospitals: [],
      model: {
        from_hospital_id: undefined,
        qty: 0,
      },
      rules: {
        from_hospital_id: {
          required: true,
          message: "This field is required!",
          type: "integer",
        },
        qty: {
          required: true,
          message: "This field is required!",
          type: "integer",
        },
      },
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
  },

  mounted() {
    this.fetchHospitals();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    fetchHospitals(val) {
      commonService.get(this.$constants.getHospitalsUrl, val).then((res) => {
        this.hospitals = res.data.filter(
          (e) => e.id != this.record.hospital_id
        );
      });
    },

    onSubmit() {
      this.loadingStart();
      commonService
        .store(this.$constants.hospitalProductImportFromHospitalUrl, {
          ...this.model,
          hospital_id: this.record.hospital_id,
        })
        .then((res) => {
          this.loadingStop();
          this.close();
          this.$message.success(res.message);
          this.$emit("callback");
        })
        .catch((err) => {
          this.loadingStop();
          this.$message.error(err);
        });
    },
  },
};
</script>

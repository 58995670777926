<template>
  <template v-if="!atx">
    <a-card size="small">
      <a-empty />
    </a-card>
  </template>

  <template v-else>
    <div class="monitor">
      <div class="monitor-top">
        <div class="monitor-message">
          <h3>
            {{ caseReport.patient_first_name }}
            {{ caseReport.patient_last_name }} | MR:
            {{ caseReport.mr_number }} | Acct:
            {{ caseReport.data.account_number ?? "N/A" }} | DOB:
            {{ $customDate.mdy(caseReport.data.dob) }} | Gender:
            {{ caseReport.data.gender }} | Allergies:
            {{ caseReport.data.allergies ?? "N/A" }} | BSA :
            {{ caseReport.data.body_surface_area ?? "N/A" }}
          </h3>
        </div>
      </div>
      <div class="monitor-middle">
        <div class="monitor-left">
          <div class="event-actions">
            <ul class="event-action-list">
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedATX
                      ? open({
                          title: 'Checklist',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.atxChecklist',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                        })
                      : ''
                  "
                >
                  <span class="title">Checklist</span>
                  <span class="description">
                    {{ atx.checklist.submitted_at }}
                  </span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedATX
                      ? open({
                          title: 'Status',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.atxStatus',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                        })
                      : ''
                  "
                >
                  <span class="title">Status</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    open({
                      title: 'Case Data',
                      path: 'newCaseReport.drawerForm.caseData',
                      callback: callback,
                      width: '90%',
                      record: caseReport,
                    })
                  "
                >
                  <span class="title">Case Data</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action event-action-close"
                  @click="$emit('closeCaseClickEvent')"
                >
                  <span class="title">Close Case</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="monitor-right">
          <div
            class="monitor-right-middle"
            ref="atxDatatableRef"
            style="padding: 1em 0 0 0"
          >
            <div class="graph"></div>
            <div class="event-table scroller">
              <table class="event-table-compact">
                <thead>
                  <tr>
                    <th>Time</th>
                    <!-- <th>Event</th> -->
                    <th>Volume Processed</th>
                    <th>Volume Returned</th>
                    <th>Volume Anticoag</th>
                    <th>Estimated Blood Loss</th>
                    <th>Wash Volume</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="hasRecords">
                    <template
                      v-for="(item, index) in events ?? []"
                      :key="index"
                    >
                      <tr>
                        <td v-if="hasNotCompletedATX">
                          <a-popover
                            placement="left"
                            overlayClassName="overlay-popover-class"
                          >
                            <template #content>
                              <a-space>
                                <a-button
                                  type="primary"
                                  @click="handleEditEvent(item)"
                                >
                                  Edit
                                </a-button>
                                <a-popconfirm
                                  title="Confirm deletion?"
                                  ok-text="Yes"
                                  cancel-text="No"
                                  @confirm="deleteRecord(item.unique_code)"
                                >
                                  <a-button danger>Delete</a-button>
                                </a-popconfirm>
                              </a-space>
                            </template>
                            {{ item.time.slice(0, 5) ?? "-" }}
                          </a-popover>
                        </td>
                        <td v-else>
                          {{ item.time.slice(0, 5) ?? "-" }}
                        </td>
                        <!-- <td>{{ item.event }}</td> -->
                        <template v-if="['Comment'].includes(item.event)">
                          <td colspan="6" align="left">
                            {{ item.comment }}
                          </td>
                        </template>
                        <template
                          v-else-if="['Shift Change'].includes(item.event)"
                        >
                          <td colspan="6" align="left">
                            <span class="text-medium">
                              {{ item.note }}
                              {{
                                item.duration
                                  ? `(${$comman.secondsToHms1(item.duration)})`
                                  : ""
                              }}
                              [Date: {{ item.date }}]
                            </span>
                          </td>
                        </template>
                        <template v-else>
                          <td>{{ item.volume_processed ?? "-" }}</td>
                          <td>{{ item.volume_returned ?? "-" }}</td>
                          <td>{{ item.volume_anticoag ?? "-" }}</td>
                          <td>{{ item.estimated_blood_loss ?? "-" }}</td>
                          <td>{{ item.wash_volume ?? "-" }}</td>
                          <td>{{ item.comment ?? "-" }}</td>
                        </template>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="7" align="left">
                        <strong>No Data Found.</strong>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot v-if="hasRecords">
                  <tr>
                    <th>Total</th>
                    <th>{{ totalVolume("volume_processed") }}</th>
                    <th>{{ totalVolume("volume_returned") }}</th>
                    <th>{{ totalVolume("volume_anticoag") }}</th>
                    <th>{{ totalVolume("estimated_blood_loss") }}</th>
                    <th>{{ totalVolume("wash_volume") }}</th>
                    <th>-</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="monitor-right-bottom">
            <div class="quick-actions">
              <ul class="quick-actions-list">
                <!--<li class="quick-item">
                  <button
                    class="action"
                    :disabled="!hasRecords"
                    @click="
                      open({
                        title: 'Comment',
                        path: 'newCaseReport.drawerForm.atxComment',
                        callback: (e) => $emit('updateCaseReport', e),
                        record: caseReport,
                      })
                    "
                  >
                    Events
                  </button>
                </li> -->
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedATX
                        ? open({
                            title: 'Shift Change',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.shiftChange',
                            callback: redirectToIndexPage,
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Shift Change
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { commonService } from "../../../services";

export default {
  props: [
    "caseReport",
    "uuid",
    "atx",
    "isCompleted",
    "isAdminLiveChartingPage",
  ],

  emits: ["updateCaseReport", "closeCaseClickEvent"],

  computed: {
    hasNotCompletedATX() {
      return !this.isCompleted || this.isAdminLiveChartingPage;
    },

    onlyActiveRecords() {
      return this.atx?.data
        ? this.atx?.data.filter((item) => !item.deleted_at)
        : [];
    },

    hasRecords() {
      return this.onlyActiveRecords.length > 0 ? true : false;
    },

    events() {
      if (!this.hasRecords) {
        return [];
      }
      return this.atx?.data
        .filter((e) => !e.deleted_at)
        .sort((a, b) =>
          moment(a.time, "HH:mm:ss").isAfter(moment(b.time, "HH:mm:ss"))
            ? 1
            : -1
        );
    },
  },

  methods: {
    ...mapActions("modal", ["open"]),

    callback() {
      // console.log("callback::");
    },

    handleEditEvent(item) {
      let path = "newCaseReport.drawerForm.";
      let title = `Edit ${item.event}`;
      if (item.event == "Status") {
        path += "atxStatus";
      } else if (item.event == "Comment") {
        path += "atxComment";
      }
      this.open({
        title,
        path,
        callback: (e) => this.$emit("updateCaseReport", e),
        record: this.caseReport,
        extra: item,
      });
    },

    deleteRecord(unique_code) {
      commonService
        .store(this.$constants.caseReportStoreATXRecordUrl, {
          unique_code,
          uuid: this.uuid,
          delete: true,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.$emit("updateCaseReport", res.data);
        })
        .catch((err) => this.$message.error(err));
    },

    totalVolume(key) {
      return this.events.reduce((pre, curr) => pre + (curr[key] ?? 0), 0);
    },

    redirectToIndexPage() {
      this.$router.push({
        name:
          this.$route.name == "admin.case-report-live-charting"
            ? "admin.case-report"
            : "case-report",
      });
    },
  },
};
</script>

<template>
  <a-divider orientation="left">NRP Live Charting</a-divider>

  <a-row :gutter="[20, 0]">
    <a-col :span="12">
      <a-card size="small" bordered>
        <a-row type="flex">
          <a-col flex="35px"> N/A </a-col>
          <a-col flex="35px"><CheckOutlined /></a-col>
          <a-col flex="calc(100% - 70px)">
            <strong>PRE-PROCEDURE CHECKLIST</strong>
          </a-col>
        </a-row>

        <template
          v-for="option in $constants.nrpPreProcedureChecklist"
          :key="option"
        >
          <a-row type="flex">
            <a-col flex="35px">
              <i
                v-if="
                  nrp?.pre_procedure_checklist?.not_applicable?.includes(option)
                "
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="35px">
              <i
                v-if="nrp?.pre_procedure_checklist?.checked?.includes(option)"
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="calc(100% - 70px)">
              {{ option }}
              <template v-if="option == 'Units PRBCs Available'">
                <span style="text-decoration: underline">
                  {{ nrp?.pre_procedure_checklist?.prbc_units }}
                </span>
              </template>
            </a-col>
          </a-row>
        </template>

        <a-descriptions
          class="mt-sm-1"
          size="small"
          bordered
          :labelStyle="{ color: '#666666', width: '150px' }"
          :column="{ sm: 1 }"
        >
          <a-descriptions-item label="Signature:">
            <img :width="200" :src="nrp.pre_procedure_checklist.sign" />
          </a-descriptions-item>
          <a-descriptions-item label="Submitted At:">
            {{ nrp.pre_procedure_checklist.submitted_at ?? "N/A" }}
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
    </a-col>

    <a-col v-if="!record?.data?.nrp_is_dry_run_case ?? false" :span="12">
      <a-card size="small" bordered>
        <a-row type="flex">
          <a-col flex="35px"> N/A </a-col>
          <a-col flex="35px"><CheckOutlined /></a-col>
          <a-col flex="calc(100% - 70px)">
            <strong>INITIATION CHECKLIST</strong>
          </a-col>
        </a-row>

        <template
          v-for="option in $constants.nrpInitiationChecklist"
          :key="option"
        >
          <a-row type="flex">
            <a-col flex="35px">
              <i
                v-if="
                  nrp?.initiation_checklist?.not_applicable?.includes(option)
                "
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="35px">
              <i
                v-if="nrp?.initiation_checklist?.checked?.includes(option)"
                class="ti ti-checkbox ti-lg"
              />
              <i v-else class="ti ti-square ti-lg"></i>
            </a-col>
            <a-col flex="calc(100% - 70px)">
              {{ option }}
              <template
                v-if="
                  option ==
                  'A-NRP, Cross clamp placed across aorta superior to NRP arterial cannula'
                "
              >
                <span style="text-decoration: underline">
                  {{ nrp?.initiation_checklist?.anrp_cross_clamp_time }}
                </span>
              </template>
              <template v-if="option == 'Confirmed Head Vessel Occlusion'">
                <span style="text-decoration: underline">
                  {{ nrp?.initiation_checklist?.hv_occlustion_time }}
                </span>
              </template>
              <template v-if="option == 'Head Vessels Vented'">
                <span style="text-decoration: underline">
                  {{ nrp?.initiation_checklist?.hv_vented_time }}
                </span>
              </template>
            </a-col>
          </a-row>
        </template>

        <a-descriptions
          class="mt-sm-1"
          size="small"
          bordered
          :labelStyle="{ color: '#666666', width: '150px' }"
          :column="{ sm: 1 }"
        >
          <a-descriptions-item label="Signature:">
            <img :width="200" :src="nrp.initiation_checklist.sign" />
          </a-descriptions-item>
          <a-descriptions-item label="Submitted At:">
            {{ nrp.initiation_checklist?.submitted_at ?? "N/A" }}
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
    </a-col>
  </a-row>

  <a-table
    class="mt-sm-1"
    :rowKey="(_, index) => index"
    :columns="nrpTablecolumns"
    :data-source="nrpEvents"
    bordered
    size="small"
    :pagination="false"
    :scroll="{ x: true, y: 0 }"
  >
    <template #fio2CustomTitle> FiO<sub>2</sub> </template>
    <template #tempCustomTitle> Temp (<sup>o</sup>C) </template>
    <template #phCustomTitle> <sub>p</sub>H </template>
    <template #pco2CustomTitle> PCO<sub>2</sub> </template>
    <template #po2CustomTitle> PO<sub>2</sub> </template>
    <template #hco3CustomTitle> HCO<sub>3</sub> </template>
    <template #o2SatCustomTitle> O<sub>2</sub> SAT </template>
    <template #kplusCustomTitle> K<sup>+</sup> </template>
    <template #naplusCustomTitle> Na<sup>+</sup> </template>
  </a-table>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { h } from "vue";

export default {
  name: "nrp-live-charting",
  props: ["nrp", "cpb", "record"],

  components: { CheckOutlined },

  data() {
    return {
      nrpTablecolumns: [
        {
          title: "Time",
          dataIndex: "time",
          key: "time",
          fixed: "left",
          customRender: this.cpbsColspanRender,
        },
        {
          title: "Event",
          dataIndex: "event",
          key: "event",
          fixed: "left",
          customRender: this.cpbsColspanRender,
        },
        {
          title: "Pressure (mmHg)",
          children: [
            {
              title: "ART",
              dataIndex: "art_pressure",
              key: "art_pressure",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "VAC",
              dataIndex: "vacuum",
              key: "vacuum",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          title: "Pump",
          children: [
            {
              title: "LINE",
              dataIndex: "line_pressure",
              key: "line_pressure",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Blood Flow",
              dataIndex: "blood_flow",
              key: "blood_flow",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "RPM",
              dataIndex: "rpm",
              key: "rpm",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          slots: {
            title: "tempCustomTitle",
          },
          children: [
            {
              title: "Core Temp",
              dataIndex: "core_temp",
              key: "core_temp",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "H/C Temp",
              dataIndex: "hc_temp",
              key: "hc_temp",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          title: "Sec.",
          children: [
            {
              title: "ACT",
              dataIndex: "act",
              key: "act",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          title: "Gases",
          children: [
            {
              dataIndex: "fio2",
              key: "fio2",
              slots: {
                title: "fio2CustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Sweep",
              dataIndex: "sweep",
              key: "sweep",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          title: "Blood Gas Analysis",
          children: [
            {
              dataIndex: "ph",
              key: "ph",
              slots: {
                title: "phCustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              dataIndex: "pco2",
              key: "pco2",
              slots: {
                title: "pco2CustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              dataIndex: "po2",
              key: "po2",
              slots: {
                title: "po2CustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              dataIndex: "hco3",
              key: "hco3",
              slots: {
                title: "hco3CustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              title: "BASE",
              dataIndex: "base",
              key: "base",
              customRender: this.cpbsColspanRender,
            },
            {
              dataIndex: "o2_sat",
              key: "o2_sat",
              slots: {
                title: "o2SatCustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
          ],
        },
        {
          title: "Chemistry",
          children: [
            {
              title: "Hb/HCT",
              dataIndex: "hb/hct",
              key: "hb/hct",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "iCa",
              dataIndex: "ica",
              key: "ica",
              customRender: this.cpbsColspanRender,
            },
            {
              dataIndex: "kplus",
              key: "kplus",
              slots: {
                title: "kplusCustomTitle",
              },
              customRender: this.cpbsColspanRender,
            },
            {
              title: "GLU",
              dataIndex: "glu",
              key: "glu",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "Lac",
              dataIndex: "lac",
              key: "lac",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "AST",
              dataIndex: "ast",
              key: "ast",
              customRender: this.cpbsColspanRender,
            },
            {
              title: "ALT",
              dataIndex: "alt",
              key: "alt",
              customRender: this.cpbsColspanRender,
            },
          ],
        },
      ],
    };
  },

  computed: {
    nrpEvents() {
      if (this.nrp?.data) {
        let data = this.nrp.data
          .filter((e) => !e.deleted_at)
          .map((item) => {
            if (!item.employee_id) {
              item.employee_id = this.record.employee_id;
            }
            if (!item.date) {
              item.date = this.$customDate.mdy(this.record.date);
              item.datetime = `${item.date} ${item.time}`;
            }
            return item;
          })
          .sort((a, b) =>
            moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
              moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
            )
              ? 1
              : -1
          );

        if (data.length != 0) {
          let firstDate = data[0].date;
          let lastDate = data[data.length - 1].date;
          let range = this.$comman.getDateRange(firstDate, lastDate);
          range.forEach((date, idx) => {
            if (idx != 0) {
              data.push({
                event: "Date change",
                date: date,
                time: "00:00:00",
                datetime: moment(`${date} 00:00:00`, "MM-DD-YYYY HH:mm:ss"),
                comment: `Date: ${date}`,
              });
            }
          });
        }

        return data.sort((a, b) =>
          moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
            moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
          )
            ? 1
            : -1
        );
      }
      return [];
    },
  },

  methods: {
    cpbsColspanRender(data) {
      if (["NRP", "Shift Change"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else if (data.column.key == "event") {
          return data.record.event;
        } else if (data.column.key == "art_pressure") {
          return {
            children: `${data.record.note} ${
              data.record.duration
                ? `(${moment
                    .utc(data.record.duration * 1000)
                    .format("HH:mm:ss")})`
                : ""
            } [Date: ${data.record.date}]`,
            props: { colSpan: 23 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["Comment"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else if (data.column.key == "event") {
          return data.record.event;
        } else if (data.column.key == "art_pressure") {
          return {
            children: [
              data.record?.event_type ?? "",
              data.record?.comment ?? "",
            ]
              .filter((e) => e != "")
              .join(" | "),
            props: { colSpan: 23 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["Medication"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else if (data.column.key == "event") {
          return data.record.event;
        } else if (data.column.key == "art_pressure") {
          return {
            children: h("div", {
              innerHTML: this.medicationEvent(data.record),
            }),
            props: { colSpan: 23 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["+ Volume"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else if (data.column.key == "event") {
          return data.record.event;
        } else if (data.column.key == "art_pressure") {
          return {
            children: h("div", {
              innerHTML: this.plusVolumeEvent(data.record),
            }),
            props: { colSpan: 23 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["- Volume"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else if (data.column.key == "event") {
          return data.record.event;
        } else if (data.column.key == "art_pressure") {
          return {
            children: h("div", {
              innerHTML: this.minusVolumeEvent(data.record),
            }),
            props: { colSpan: 23 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["Blood Product"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else if (data.column.key == "event") {
          return data.record.event;
        } else if (data.column.key == "art_pressure") {
          return {
            children: h("div", {
              innerHTML: this.bloodProductEvent(data.record),
            }),
            props: { colSpan: 23 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["Blood Gas"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else if (data.column.key == "event") {
          return data.record.event;
        } else if (data.column.key == "hb/hct") {
          let res = data.record.hb ?? "-";
          res += "/";
          res += data.record.hct ?? "-";
          return res;
        } else {
          return data.text ?? "-";
        }
      } else if (["Cardioplegia"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else if (data.column.key == "ph") {
          return {
            children: h("div", {
              innerHTML: this.cardioplegiaEvent(data.record),
            }),
            props: { colSpan: 11 },
          };
        } else {
          if (
            [
              "pco2",
              "po2",
              "hco2",
              "base",
              "o2_sat",
              "hb/hct",
              "ica",
              "kplus",
              "glu",
            ].includes(data.column.key)
          ) {
            return {
              props: { colSpan: 0 },
            };
          } else {
            return data.text ?? "-";
          }
        }
      } else if (["Date change"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return {
            children: h("div", {
              innerHTML: data.record.comment,
            }),
            props: {
              colSpan: 25,
              style: { backgroundColor: "#eee", borderColor: "#fff" },
            },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else if (data.column.key == "event") {
          return data.record.event;
        } else {
          return data.text ?? "-";
        }
      }
    },

    medicationEvent(item) {
      return `<span class="text-medium">Drug:</span> ${
        item.drug
      } | <span class="text-medium">Dose:</span> ${item.dose} ${
        item.doseUnit
      } | <span class="text-medium">Administered By:</span> ${
        item.administered_by
      } | <span class="text-medium">Lot No#:</span> ${
        item.lot_no ?? "N/A"
      } | <span class="text-medium">Expiry Date:</span> ${
        item.expiry_date ?? "N/A"
      }`;
    },

    plusVolumeEvent(item) {
      return [
        { name: "Pre-Bypass Volume", key: "pre_bypass_volume" },
        { name: "Prime Volume", key: "prime_volume" },
        { name: "Crystalloid", key: "crystalloid" },
        { name: "Colloid", key: "colloid" },
        { name: "0.9% Normal Saline Solution", key: "normal_saline_solution" },
        { name: "Normosol-R, pH 7.4", key: "normosol_r_ph" },
        { name: "Isolyte S, pH 7.4", key: "isolyte_s_ph" },
      ]
        .reduce((pre, curr) => {
          if (item[curr.key] && !["", null].includes(item[curr.key])) {
            pre.push(
              `<span class="text-medium"> ${curr.name} : </span> ${
                item[curr.key]
              } mL`
            );
          }
          return pre;
        }, [])
        .join(" | ");
    },

    minusVolumeEvent(item) {
      return [
        { name: "Autologous Prime", key: "autologous_prime" },
        { name: "Ultrafiltration", key: "ultrafiltration" },
        { name: "Urine", key: "urine" },
        { name: "Residual CPB Volume", key: "residual_cpb_volume" },
      ]
        .reduce((pre, curr) => {
          if (item[curr.key] && !["", null].includes(item[curr.key])) {
            pre.push(
              `<span class="text-medium"> ${curr.name} : </span> ${
                item[curr.key]
              } mL`
            );
          }
          return pre;
        }, [])
        .join(" | ");
    },

    bloodProductEvent(item) {
      return `<span class="text-medium"> Product Type : </span> ${
        item.product_type ?? "N/A"
      } | <span class="text-medium"> Unit Type: </span> ${
        item.unit_type ?? "N/A"
      } | <span class="text-medium"> Unit ID No: </span> ${
        item.unit_id_no ?? "N/A"
      } | <span class="text-medium"> Unit Expiration Date: </span> ${
        item.unit_expiration_date ?? "N/A"
      } | <span class="text-medium"> Volume: </span> ${
        item.blood_product_volume ?? "N/A"
      } mL`;
    },

    cardioplegiaEvent(item) {
      let res = [];
      if (item.route.length > 0) {
        res.push(
          `<span class="text-medium"> Route : </span> ${item.route.join(", ")}`
        );
      }
      if (item.radio) {
        res.push(`<span class="text-medium"> Ratio : </span> ${item.radio}`);
        if (item.radio == "Other") {
          res.push(
            `<span class="text-medium"> Other Ratio : </span> ${item.other_radio}`
          );
        }
      }
      return res.join(" | ");
    },
  },
};
</script>

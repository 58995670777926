<template>
  <a-modal
    v-model:visible="modelValue"
    size="small"
    title="No. of Cases (YTD)"
    @cancel="() => $emit('update:modelValue', false)"
    :footer="false"
    :width="850"
  >
    <a-spin :spinning="loading">
      <div id="columnChart" />
    </a-spin>
  </a-modal>
</template>

<script>
import { DualAxes } from "@antv/g2plot";
import { commonService } from "../../../services";

export default {
  props: ["modelValue", "hospitalId"],
  emits: ["update:modelValue"],

  data() {
    return {
      loading: true,
      columnChart: null,
      data: [],
      chartConfigOld: {
        xField: "month",
        yField: "count",
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        meta: {
          month: {
            alias: "Month",
          },
          amount: {
            alias: "Count",
          },
        },
        tooltip: {
          formatter: (v) => ({
            name: "Count",
            value: v.count,
          }),
        },
        label: {
          position: "top",
        },
        legend: false,
        smooth: true,
        animation: {
          appear: {
            animation: "path-in",
            duration: 5000,
          },
        },
        color: "#023958",
      },
      chartConfig: {
        xField: "month",
        yField: ["count", "avg"],
        geometryOptions: [
          {
            geometry: "column",
            label: {
              position: "top",
              formatter: (v) => v.count,
            },
            tooltip: {
              formatter: (v) => ({
                name: "Count",
                value: v.count,
              }),
            },
            label: {
              position: "top",
              formatter: (v) => v.count,
            },
          },
          {
            geometry: "line",
            lineStyle: {
              lineWidth: 2,
            },
            color: "red",
            tooltip: {
              formatter: (v) => ({
                name: "AVG",
                value: v.avg,
              }),
            },
            label: {
              position: "top",
              formatter: (v) => v.avg,
            },
          },
        ],
        yAxis: [{}, {}],
        meta: {
          month: {
            alias: "Month",
          },
          count: {
            alias: "Count",
          },
          avg: {
            alias: "AVG",
          },
        },
        legend: {
          layout: "horizontal",
          offsetX: 8,
          position: "top",
          title: {
            text: "Average Volume YTD",
          },
        },
      },
    };
  },

  watch: {
    modelValue(value) {
      if (value) {
        this.fetch();
      }
    },
  },

  mounted() {
    // this.prepareChart();
  },

  methods: {
    fetch() {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalNoOfCaseReportsPerMonthUrl, {
          hospital_id: this.hospitalId,
        })
        .then((res) => {
          this.data = res.data;

          this.prepareChart();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async prepareChart() {
      console.log(this.data);
      let counts = this.data.map((e) => e.count);
      let maxCount = Math.max(...counts);
      let max = Math.round(maxCount + maxCount * 0.1);
      this.chartConfig.yAxis[0] = { min: 0, max };
      this.chartConfig.yAxis[1] = { min: 0, max };
      let data = {
        data: [this.data, this.data],
        ...this.chartConfig,
      };

      if (this.columnChart) {
        this.columnChart.update(data);
      } else {
        this.columnChart = new DualAxes("columnChart", data);
        await this.$nextTick(() => {
          this.columnChart.render();
        });
      }
    },
  },
};
</script>

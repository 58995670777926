<template>
  <a-spin :spinning="loading">
    <a-card title="Sales Performance ($)" size="small">
      <div ref="salesPerformanceChart"></div>
    </a-card>
  </a-spin>
</template>

<script>
import { DualAxes } from "@antv/g2plot";

export default {
  name: "sales-performance-chart",

  props: ["chartData", "loading"],

  data() {
    return {
      chart: null,
      config: {
        xField: "month",
        yField: ["amount", "profit_margin"],
        yAxis: [{}, {}],
        legend: {
          position: "top",
        },
        geometryOptions: [
          {
            geometry: "column",
            seriesField: "type",
            isGroup: "true",
            columnStyle: {
              radius: [5, 5, 0, 0],
            },
            // color: ({ year }) => {
            //   return year === "2024E" ? "#6294FA" : "orange";
            // },
            tooltip: {
              formatter: (v) => ({
                name: v.type,
                value: this.$comman.withCurrency(v.amount),
              }),
            },
            // label: {
            //   position: "top",
            //   formatter: (v) => this.$comman.abbreviatedNumber(v.amount),
            // },
          },
          {
            geometry: "line",
            lineStyle: {
              lineWidth: 2,
            },
            color: "red",
            tooltip: {
              formatter: (v) => ({
                name: "Gross Profit Margin",
                value: `${v.profit_margin}%`,
              }),
            },
            // label: {
            //   position: "top",
            //   formatter: (v) => `${v.profit_margin}%`,
            // },
          },
        ],
        meta: {
          month: {
            alias: "Month",
          },
          amount: {
            alias: "Amount",
          },
          profit_margin: {
            alias: "Gross Profit Margin",
          },
        },
      },
    };
  },

  computed: {
    //
  },

  mounted() {
    //
  },

  methods: {
    updateChart() {
      // Max amount set
      let amounts = this.chartData[0].map((e) => e.amount);
      let maxAmount = amounts.length > 0 ? Math.max(...amounts) : 0;
      this.config.yAxis[0] = {
        max: Math.round(maxAmount + maxAmount * 0.2),
      };

      // Max profit margin set
      let profitMargin = this.chartData[1].map((e) => e.profit_margin);
      let maxProfitMargin =
        profitMargin.length > 0 ? Math.max(...profitMargin) : 0;
      let minProfitMargin =
        profitMargin.length > 0 ? Math.min(...profitMargin) : 0;
      this.config.yAxis[1] = {
        max: Math.round(maxProfitMargin + maxProfitMargin * 0.1),
        min: Math.round(minProfitMargin - minProfitMargin * 0.2),
      };

      let data = {
        data: this.chartData,
        ...this.config,
      };
      if (this.chart) {
        this.chart.update(data);
      } else {
        this.chart = new DualAxes(this.$refs["salesPerformanceChart"], data);
        this.chart.render();
      }
    },
  },

  watch: {
    chartData: {
      deep: true,
      handler: function () {
        this.updateChart();
      },
    },
  },
};
</script>

<template>
  <a-layout-content>
    <page-header>
      <template #title> Profit & Loss (YTD)</template>
      <template #buttons>
        <a-space align="end" :size="10">
          <a-range-picker
            v-model:value="classWiseProfitAndLossDateRange"
            :allowClear="false"
            :ranges="ranges"
            style="width: 220px"
            :format="$constants.datepickerFormat"
          />

          <a-button
            type="primary"
            ghost
            :loading="downloadPdfBtnLoading"
            @click="() => downloadPdf()"
          >
            <a-space> <i class="ti ti-download ti-lg"></i> PDF </a-space>
          </a-button>

          <a-button
            type="primary"
            ghost
            :loading="downloadExcelBtnLoading"
            @click="downloadExcel"
          >
            <a-space> <i class="ti ti-download ti-lg"></i> Excel </a-space>
          </a-button>
        </a-space>
      </template>
    </page-header>

    <a-row :gutter="[16, 16]">
      <a-col :xs="24" :md="8" :lg="12">
        <a-row :gutter="[16, 16]">
          <a-col :xs="24" :lg="12">
            <a-card>
              <a-spin :spinning="classWiseProfitAndLossLoading">
                <a-statistic
                  title="Gross Profit (YTD)"
                  :value="grossProfitInPercentage"
                >
                </a-statistic>
              </a-spin>
            </a-card>
          </a-col>

          <a-col :xs="24" :lg="12">
            <a-card>
              <a-spin :spinning="classWiseProfitAndLossLoading">
                <a-statistic
                  title="Net Profit (YTD)"
                  :value="netProfitInPercentage"
                >
                </a-statistic>
              </a-spin>
            </a-card>
          </a-col>

          <a-col :xs="24" :lg="12">
            <a-card>
              <a-spin :spinning="classWiseProfitAndLossLoading">
                <a-statistic :value="berryRatio">
                  <template #title>
                    <a-space>
                      Berry Ratio (YTD)
                      <a-tooltip placement="top">
                        <template #title>
                          This ratio is an indicator of a company's profit in a
                          given period; a ratio of 1 or more indicates that a
                          company's profit is above operating expenses, while a
                          ratio below 1 indicates that a company is losing
                          money.
                        </template>
                        <a-button size="small" type="link">
                          <i class="ti ti-info-circle ti-lg"> </i>
                        </a-button>
                      </a-tooltip>
                    </a-space>
                  </template>
                </a-statistic>
              </a-spin>
            </a-card>
          </a-col>

          <a-col :xs="24" :lg="12">
            <a-card>
              <a-spin :spinning="classWiseProfitAndLossLoading">
                <a-statistic :value="sopMargin">
                  <template #title>
                    <a-space> Sale of Product Margin (%) </a-space>
                  </template>
                </a-statistic>
              </a-spin>
            </a-card>
          </a-col>
        </a-row>
      </a-col>

      <a-col :xs="24" :md="16" :lg="12">
        <a-card size="small">
          <a-spin :spinning="classWiseProfitAndLossLoading">
            <table class="finance-profit-and-loss-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th style="text-align: right">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in classWiseProfitAndLossData"
                  :key="`${index}_pl`"
                  :class="item.type"
                >
                  <profitAndLossAnalyticTableRows :item="item" />
                </tr>
              </tbody>
            </table>
          </a-spin>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script>
import {
ContactsFilled,
DollarCircleFilled,
PlusCircleFilled,
SearchOutlined,
SettingFilled,
ShoppingFilled,
} from "@ant-design/icons-vue";
import moment from "moment";
import { commonService } from "../../services/common.service";
import profitAndLossAnalyticTableRows from "../hospital/showTabs/profitAndLossAnalyticTableRows.vue";

export default {
  components: {
    SearchOutlined,
    ShoppingFilled,
    DollarCircleFilled,
    SettingFilled,
    ContactsFilled,
    PlusCircleFilled,
    profitAndLossAnalyticTableRows,
  },

  data() {
    return {
      columns: [
        {
          title: "#",
          dataIndex: "name",
          key: "name",
          customRender: () => "Amount",
        },
        {
          title: "Current",
          dataIndex: "current",
          key: "current",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "1-30 Days",
          dataIndex: "1_30_days",
          key: "1_30_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "31-60 Days",
          dataIndex: "31_60_days",
          key: "31_60_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "61-90 Days",
          dataIndex: "61_90_days",
          key: "61_90_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "Over 90 Days",
          dataIndex: "over_90_days",
          key: "over_90_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
      ],
      data: null,
      loading: true,

      // PROFIT AND LOSS
      classWiseProfitAndLossData: [],
      classWiseProfitAndLossDateRange: [
        moment().startOf("year"),
        moment().endOf("year"),
      ],
      classWiseProfitAndLossLoading: true,
      downloadPdfBtnLoading: false,
      downloadExcelBtnLoading: false,
      profitAndLossColumns: [
        {
          title: "#",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          customRender: ({ text }) => this.$comman.currencyformatter(text),
        },
      ],
    };
  },

  computed: {
    netIncome() {
      return (
        this.classWiseProfitAndLossData.find(
          (e) => e.name == "Total Income" && e.type == "summary"
        )?.amount ?? 0
      );
    },

    netProfit() {
      return (
        this.classWiseProfitAndLossData.find(
          (e) => e.name == "Net Income" && e.type == "summary"
        )?.amount ?? 0
      );
    },

    grossProfit() {
      return (
        this.classWiseProfitAndLossData.find(
          (e) => e.name == "Gross Profit" && e.type == "summary"
        )?.amount ?? 0
      );
    },

    totalExpenses() {
      return (
        this.classWiseProfitAndLossData.find(
          (e) => e.name == "Total Expenses" && e.type == "summary"
        )?.amount ?? 0
      );
    },

    netProfitInPercentage() {
      return this.netIncome != 0
        ? `${parseFloat((this.netProfit / this.netIncome) * 100).toFixed(2)}%`
        : `${0}%`;
    },

    grossProfitInPercentage() {
      return this.netIncome != 0
        ? `${parseFloat((this.grossProfit / this.netIncome) * 100).toFixed(2)}%`
        : `${0}%`;
    },

    berryRatio() {
      return this.totalExpenses != 0
        ? parseFloat(this.grossProfit / this.totalExpenses).toFixed(2)
        : 0;
    },

    sopMargin() {
      let goodsSold = this.retriveName("55100 Cost of Products Sold");
      let productIncome = this.retriveName("48080 Sales of Product Income");
      return `${
        productIncome > 0
          ? Number(
              parseFloat(
                ((productIncome - goodsSold) / productIncome) * 100
              ).toFixed(2)
            )
          : 0
      }%`;
    },

    ranges() {
      let res = {};
      for (let index = 0; index < 5; index++) {
        res[moment().subtract(index, "years").startOf("year").format("YYYY")] =
          [
            moment().subtract(index, "years").startOf("year"),
            moment().subtract(index, "years").endOf("year"),
          ];
      }
      return res;
    },
  },

  async mounted() {
    await this.getClassWiseProfitAndLoss();
  },

  methods: {
    downloadPdf() {
      const year = `${this.$customDate.mdy(
        this.classWiseProfitAndLossDateRange[0]
      )} - ${this.$customDate.mdy(this.classWiseProfitAndLossDateRange[1])}`;
      this.downloadPdfBtnLoading = true;

      commonService
        .downloadFile(
          this.$constants.financeProfitAndLoss,
          {
            start_date: this.$customDate.ymd(
              this.classWiseProfitAndLossDateRange[0]
            ),
            end_date: this.$customDate.ymd(
              this.classWiseProfitAndLossDateRange[1]
            ),
            export_pdf: true,
          },
          `ProfitAndLoss - ${year}.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.downloadPdfBtnLoading = false;
        });
    },

    downloadExcel() {
      const year = `${this.$customDate.mdy(
        this.classWiseProfitAndLossDateRange[0]
      )} - ${this.$customDate.mdy(this.classWiseProfitAndLossDateRange[1])}`;
      this.downloadExcelBtnLoading = true;

      commonService
        .downloadFile(
          this.$constants.financeProfitAndLoss,
          {
            start_date: this.$customDate.ymd(
              this.classWiseProfitAndLossDateRange[0]
            ),
            end_date: this.$customDate.ymd(
              this.classWiseProfitAndLossDateRange[1]
            ),
            export_excel: true,
          },
          `ProfitAndLoss - ${year}.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.downloadExcelBtnLoading = false;
        });
    },

    getClassWiseProfitAndLoss() {
      this.classWiseProfitAndLossLoading = true;
      commonService
        .get(this.$constants.financeProfitAndLoss, {
          start_date: this.$customDate.ymd(
            this.classWiseProfitAndLossDateRange[0]
          ),
          end_date: this.$customDate.ymd(
            this.classWiseProfitAndLossDateRange[1]
          ),
        })
        .then((res) => {
          if (res.data?.Rows?.Row) {
            this.classWiseProfitAndLossData = this.makeProfitAndLossArray(
              res.data.Rows.Row
            ).flat(1);
          }
        })
        .finally(() => {
          this.classWiseProfitAndLossLoading = false;
        });
    },

    makeProfitAndLossArray(rows, indent = 0) {
      return rows.map((e) => {
        let temp = [];
        if (e.Header) {
          temp.push({
            name: e.Header.ColData[0].value,
            amount: 0,
            type: "header",
            indent,
          });
        }
        if (e.ColData) {
          temp.push({
            name: e.ColData[0].value,
            amount:
              e.ColData[1]?.value && e.ColData[1]?.value != ""
                ? e.ColData[1].value
                : 0,
            type: "col_data",
            indent,
          });
        }
        if (e?.Rows?.Row) {
          temp.push(this.makeProfitAndLossArray(e.Rows.Row, ++indent).flat());
        }
        if (e.Summary) {
          temp.push({
            name: e.Summary.ColData[0].value,
            amount:
              e.Summary.ColData[1]?.value && e.Summary.ColData[1]?.value != ""
                ? e.Summary.ColData[1].value
                : 0,
            type: "summary",
            indent,
          });
        }
        return temp;
      });
    },

    retriveName(name, data = this.classWiseProfitAndLossData) {
      return data.reduce((pre, curr) => {
        if (Array.isArray(curr)) {
          return pre + this.retriveName(name, curr);
        } else {
          return pre + (curr.name == name ? Number(curr.amount) : 0);
        }
      }, 0);
    },
  },

  watch: {
    classWiseProfitAndLossDateRange() {
      this.getClassWiseProfitAndLoss();
    },
  },
};
</script>

<style lang="less">
.finance-profit-and-loss-table {
  width: 100%;

  thead {
    tr {
      th {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
  }

  tbody {
    tr.summary {
      td {
        border-top: 1px solid gray;
        font-weight: 500;
      }
    }
  }
}
</style>

<template>
  <a-row :gutter="[16, 16]">
    <a-col :span="12">
      <a-spin :spinning="loading">
        <a-card title="Income By Type (YTD)" size="small">
          <div ref="incomeByTypeChart"></div>
        </a-card>
      </a-spin>
    </a-col>
    <a-col :span="12">
      <a-spin :spinning="loading">
        <a-card title="Income By Service (YTD)" size="small">
          <div ref="incomeByServiceChart"></div>
        </a-card>
      </a-spin>
    </a-col>
  </a-row>
</template>

<script>
import { Pie } from "@antv/g2plot";
import { commonService } from "../../../services";

export default {
  name: "income-by-type",

  data() {
    return {
      loading: false,
      data: {
        income_by_type: [],
        income_by_service: [],
      },
      chart: null,
      incomeByServiceChart: null,
    };
  },

  computed: {
    //
  },

  async mounted() {
    this.fetchData();

    this.chart = new Pie(this.$refs["incomeByTypeChart"], {
      data: this.data.income_by_type,
      ...this.config("income_by_type"),
    });
    this.incomeByServiceChart = new Pie(this.$refs["incomeByServiceChart"], {
      data: this.data.income_by_service,
      ...this.config("income_by_service"),
    });

    await this.$nextTick(() => {
      this.chart.render();
      this.incomeByServiceChart.render();
    });
  },

  methods: {
    config(key) {
      return {
        appendPadding: 10,
        angleField: "value",
        colorField: "type",
        radius: 0.9,
        legend: {
          position: "top",
          maxRow: 4,
          flipPage: false,
          itemValue: {
            formatter: (text) => {
              let value =
                this.data[key].find((e) => e.type == text)?.value ?? 0;
              return `(${this.$comman.withCurrency(value)})`;
            },
            style: {
              opacity: 0.65,
            },
          },
        },
        label: {
          type: "outer",
          offset: "10%",
          content: ({ percent }) => `${(percent * 100).toFixed(1)}%`,
          style: {
            fontSize: 14,
            textAlign: "center",
          },
        },
        tooltip: {
          formatter: ({ type, value }) => ({
            name: type,
            value: this.$comman.withCurrency(value),
          }),
        },
        interactions: [{ type: "element-active" }],
      };
    },

    fetchData() {
      this.loading = true;

      commonService
        .get(this.$constants.financeDashboardIncomeByTypeUrl)
        .then(async (res) => {
          this.data = res.data;

          this.chart.changeData(this.data.income_by_type);
          this.incomeByServiceChart.changeData(this.data.income_by_service);
        })
        .catch(async (err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <template v-if="!cpb">
    <a-card size="small">
      <a-empty />
    </a-card>
  </template>

  <template v-else>
    <div class="monitor">
      <div class="monitor-top">
        <div class="monitor-message">
          <h3>
            {{ caseReport.patient_first_name }}
            {{ caseReport.patient_last_name }} | MR:
            {{ caseReport.mr_number }} | Acct:
            {{ caseReport.data.account_number ?? "N/A" }} | DOB:
            {{ $customDate.mdy(caseReport.data.dob) }} | Gender:
            {{ caseReport.data.gender }} | Allergies:
            {{ caseReport.data.allergies ?? "N/A" }} | BSA :
            {{ caseReport.data.body_surface_area ?? "N/A" }}
          </h3>
        </div>
      </div>
      <div class="monitor-middle">
        <div class="monitor-left">
          <div class="timer-actions">
            <ul class="timer-action-list">
              <li class="action-item">
                <a-spin :spinning="byPassLoading">
                  <button
                    class="timer-action"
                    :class="
                      checkTimer('Bypass').start_time &&
                      !checkTimer('Bypass').stop_time
                        ? 'timer-action-active'
                        : ''
                    "
                    @click="
                      hasNotCompletedCBP
                        ? handleTimerEvent('Bypass', 'byPassLoading')
                        : ''
                    "
                  >
                    <span class="title">
                      Bypass
                      <span style="font-weight: normal !important">
                        {{ timer("Bypass") }}
                      </span>
                    </span>

                    <span class="start">
                      {{
                        checkTimer("Bypass").start_time
                          ? checkTimer("Bypass").start_time.slice(0, 5)
                          : "-"
                      }}
                    </span>
                    <span class="stop">
                      {{
                        checkTimer("Bypass").stop_time
                          ? checkTimer("Bypass").stop_time.slice(0, 5)
                          : "-"
                      }}
                    </span>
                  </button>
                </a-spin>
              </li>
              <li class="action-item">
                <a-spin :spinning="xClampOnLoading">
                  <button
                    class="timer-action"
                    :class="
                      checkTimer('X-Clamp').start_time &&
                      !checkTimer('X-Clamp').stop_time
                        ? 'timer-action-active'
                        : ''
                    "
                    @click="
                      hasNotCompletedCBP
                        ? handleTimerEvent('X-Clamp', 'xClampOnLoading')
                        : ''
                    "
                  >
                    <span class="title">
                      X-Clamp
                      <span style="font-weight: normal !important">
                        {{ timer("X-Clamp") }}
                      </span>
                    </span>
                    <span class="start">
                      {{
                        checkTimer("X-Clamp").start_time
                          ? checkTimer("X-Clamp").start_time.slice(0, 5)
                          : "-"
                      }}
                    </span>
                    <span class="stop">
                      {{
                        checkTimer("X-Clamp").stop_time
                          ? checkTimer("X-Clamp").stop_time.slice(0, 5)
                          : "-"
                      }}
                    </span>
                  </button>
                </a-spin>
              </li>
              <li class="action-item">
                <a-spin :spinning="cirArrestLoading">
                  <button
                    class="timer-action"
                    :class="
                      checkTimer('Cir. Arrest').start_time &&
                      !checkTimer('Cir. Arrest').stop_time
                        ? 'timer-action-active'
                        : ''
                    "
                    @click="
                      hasNotCompletedCBP
                        ? handleTimerEvent('Cir. Arrest', 'cirArrestLoading')
                        : ''
                    "
                  >
                    <span class="title">
                      Cir. Arrest
                      <span style="font-weight: normal !important">
                        {{ timer("Cir. Arrest") }}
                      </span>
                    </span>
                    <span class="start">
                      {{
                        checkTimer("Cir. Arrest").start_time
                          ? checkTimer("Cir. Arrest").start_time.slice(0, 5)
                          : "-"
                      }}
                    </span>
                    <span class="stop">
                      {{
                        checkTimer("Cir. Arrest").stop_time
                          ? checkTimer("Cir. Arrest").stop_time.slice(0, 5)
                          : "-"
                      }}
                    </span>
                  </button>
                </a-spin>
              </li>
            </ul>
          </div>
          <div class="event-actions">
            <ul class="event-action-list">
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedCBP
                      ? open({
                          title: 'Checklist',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.cpbChecklist',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                        })
                      : ''
                  "
                >
                  <span class="title">Checklist</span>
                  <span class="description">
                    {{ cpb.checklist.submitted_at }}
                  </span>
                </button>
              </li>
              <li class="action-item">
                <button class="event-action" @click="showChart = !showChart">
                  <span class="title">{{ showChart ? "Table" : "Chart" }}</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedCBP
                      ? open({
                          title: 'Status',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.cpbStatus',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                          extra: { lastRecord },
                        })
                      : ''
                  "
                >
                  <span class="title">Status</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedCBP
                      ? open({
                          title: 'Blood Gas',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.cpbBloodGas',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                          extra: { lastRecord },
                        })
                      : ''
                  "
                >
                  <span class="title">Blood Gas</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedCBP
                      ? open({
                          title: 'Cardioplegia',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.cpbCardioplegia',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                          extra: { lastRecord },
                        })
                      : ''
                  "
                >
                  <span class="title">Cardioplegia</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    open({
                      title: 'Case Data',
                      width: '90%',
                      path: 'newCaseReport.drawerForm.caseData',
                      callback: callback,
                      record: caseReport,
                    })
                  "
                >
                  <span class="title">Case Data</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action event-action-close"
                  @click="$emit('closeCaseClickEvent')"
                >
                  <span class="title">Close Case</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="monitor-right">
          <div class="monitor-right-top">
            <div class="indicators scroller">
              <ul class="indicators-list">
                <li class="indicator-item">
                  <span class="title">Blood Flow</span>
                  <span class="count">{{ computedBloodFlow }}</span>
                </li>
                <li class="indicator-item">
                  <span class="title">HCT</span>
                  <span class="count">
                    {{ computedHCT != "-" ? `${computedHCT}%` : "-" }}
                  </span>
                </li>
                <li class="indicator-item">
                  <span class="title">S<sub>V</sub>O<sub>2</sub></span>
                  <span class="count">
                    {{ computedSvo2 != "-" ? `${computedSvo2}%` : "-" }}
                  </span>
                </li>
                <li class="indicator-item">
                  <span class="title">Line Pres.</span>
                  <span class="count">{{ computedLinePressure }}</span>
                </li>
                <li class="indicator-item">
                  <span class="title">Core Temp.</span>
                  <span class="count">{{ computedCoreTemp }}</span>
                </li>
                <li class="indicator-item">
                  <span class="title">Ven Temp.</span>
                  <span class="count">{{ computedVenousTemp }}</span>
                </li>
                <li class="indicator-item">
                  <span class="title">Cardiac Index</span>
                  <span class="count">{{ computedCardiacIndex }}</span>
                </li>
                <li
                  class="indicator-item"
                  @click="
                    hasNotCompletedCBP
                      ? open({
                          title: 'Volume Balance',
                          path: 'newCaseReport.drawerForm.volumeBalanceInfo',
                          callback: callback,
                          width: '90%',
                          record: caseReport,
                          extra: totalVolumeBalance,
                        })
                      : ''
                  "
                >
                  <span class="title">Balance</span>
                  <span class="count">Σ {{ computedBalance }} mL</span>
                </li>
                <li class="indicator-item">
                  <span class="title">Pre-Bypass Vitals</span>
                  <span class="count">
                    PA : {{ firstRecord ? firstRecord.pa : "-" }} | CVP :
                    {{ firstRecord ? firstRecord.cvp : "-" }}
                  </span>
                </li>
                <!-- <li class="indicator-item">
                  <span class="title">Height | Weight | BSA</span>
                  <span class="count">
                    {{ caseReport.data.height }} cm |
                    {{ caseReport.data.weight }} kg |
                    {{ caseReport.data.body_surface_area }} m<sup>2</sup>
                  </span>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="monitor-right-middle" ref="cpbDatatableRef">
            <div id="cpbChart"></div>
            <div v-if="!showChart" class="event-table scroller">
              <table class="event-table-compact" ref="cpbDatatable">
                <thead>
                  <tr>
                    <th rowspan="2">Time</th>
                    <th rowspan="2">Event</th>
                    <th colspan="1">Pressure</th>
                    <th colspan="2">Pump</th>
                    <th colspan="4">Cardioplegia</th>
                    <th colspan="3">Gases</th>
                    <th colspan="3">Temp (<sup>o</sup>C)</th>
                    <th rowspan="2">ACT</th>
                    <th rowspan="2">S<sub>V</sub>O<sub>2</sub>%</th>
                    <th rowspan="2">rSO<sub>2</sub><br />(Left)</th>
                    <th rowspan="2">rSO<sub>2</sub><br />(Right)</th>
                    <th colspan="7" class="highlight">Blood Gas Analysis</th>
                    <th colspan="4" class="highlight">Chemistry</th>
                  </tr>
                  <tr>
                    <th>ART</th>
                    <th>Flow <small>(l/min.)</small></th>
                    <th>Line Pressure</th>
                    <th>Flow</th>
                    <th>Circuit Pressure</th>
                    <th>Volume</th>
                    <th>Sinus Pressure</th>
                    <th>FiO<sub>2</sub></th>
                    <th>Sweep</th>
                    <th>Anes. Gas %</th>
                    <th>Art.</th>
                    <th>Ven.</th>
                    <th>Core</th>
                    <th class="highlight"><sub>p</sub>H</th>
                    <th class="highlight">PCO<sub>2</sub></th>
                    <th class="highlight">PO<sub>2</sub></th>
                    <th class="highlight">HCO<sub>3</sub></th>
                    <th class="highlight">BASE</th>
                    <th class="highlight">O<sub>2</sub> SAT</th>
                    <th class="highlight">Hb/HCT</th>
                    <th class="highlight">iCa</th>
                    <th class="highlight">K<sup>+</sup></th>
                    <th class="highlight">Na<sup>+</sup></th>
                    <th class="highlight">GLU</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="hasRecords">
                    <template
                      v-for="(item, index) in onlyActiveRecords ?? []"
                      :key="index"
                    >
                      <tr v-if="item.event == 'Date change'">
                        <td
                          colspan="30"
                          align="left"
                          style="
                            font-weight: 500;
                            background-color: #eee;
                            padding-left: 10px;
                          "
                        >
                          {{ item.comment }}
                        </td>
                      </tr>
                      <tr v-else>
                        <td
                          v-if="
                            (!isCompleted &&
                              item.employee_id == employee.id &&
                              item.event != 'Shift Change') ||
                            isAdminLiveChartingPage
                          "
                        >
                          <a-popover
                            placement="left"
                            overlayClassName="overlay-popover-class"
                          >
                            <template #content>
                              <a-space>
                                <a-button
                                  type="primary"
                                  @click="handleEditEvent(item)"
                                >
                                  Edit
                                </a-button>
                                <a-popconfirm
                                  title="Confirm deletion?"
                                  ok-text="Yes"
                                  cancel-text="No"
                                  @confirm="deleteRecord(item.unique_code)"
                                >
                                  <a-button danger>Delete</a-button>
                                </a-popconfirm>
                              </a-space>
                            </template>
                            {{ item.time.slice(0, 5) ?? "-" }}
                          </a-popover>
                        </td>
                        <td v-else>
                          {{ item.time.slice(0, 5) ?? "-" }}
                        </td>
                        <td>{{ item.event }}</td>
                        <template
                          v-if="
                            [
                              'Bypass',
                              'X-Clamp',
                              'Cir. Arrest',
                              'Shift Change',
                            ].includes(item.event)
                          "
                        >
                          <td colspan="28" align="left">
                            <span class="text-medium">
                              {{ item.note }}
                              {{
                                item.duration
                                  ? `(${$comman.secondsToHms1(item.duration)})`
                                  : ""
                              }}
                              [Date: {{ item.date }}]
                            </span>
                          </td>
                        </template>
                        <template
                          v-else-if="
                            ['Cooling', 'Warming'].includes(item.event)
                          "
                        >
                          <td colspan="28" align="left">
                            <span class="text-medium">Temperature:</span>
                            {{ item.temperature }}
                          </td>
                        </template>
                        <template v-else-if="['Comment'].includes(item.event)">
                          <td colspan="28" align="left">
                            {{ item.comment }}
                          </td>
                        </template>
                        <template
                          v-else-if="['Medication'].includes(item.event)"
                        >
                          <td colspan="28" align="left">
                            <span class="text-medium">Drug:</span>
                            {{ item.drug ?? "N/A" }} |
                            <span class="text-medium">Dose:</span>
                            {{ item.dose ?? "N/A" }}
                            {{ item.doseUnit ?? "N/A" }} |
                            <span class="text-medium">Administered By:</span>
                            {{ item.administered_by ?? "N/A" }} |
                            <span class="text-medium">Lot No#:</span>
                            {{ item.lot_no ?? "N/A" }} |
                            <span class="text-medium">Expiry Date:</span>
                            {{ item.expiry_date ?? "N/A" }}
                          </td>
                        </template>
                        <template v-else-if="['+ Volume'].includes(item.event)">
                          <td colspan="28" align="left">
                            <span v-html="plusVolumeEvent(item)" />
                          </td>
                        </template>
                        <template v-else-if="['- Volume'].includes(item.event)">
                          <td colspan="28" align="left">
                            <span v-html="minusVolumeEvent(item)" />
                          </td>
                        </template>
                        <template
                          v-else-if="['Blood Product'].includes(item.event)"
                        >
                          <td colspan="28" align="left">
                            <span class="text-medium"> Product Type : </span>
                            {{ item.product_type ?? "N/A" }} |
                            <span class="text-medium"> Unit Type: </span>
                            {{ item.unit_type ?? "N/A" }} |
                            <span class="text-medium"> Unit ID No: </span>
                            {{ item.unit_id_no ?? "N/A" }} |
                            <span class="text-medium">
                              Unit Expiration Date:
                            </span>
                            {{ item.unit_expiration_date ?? "N/A" }} |
                            <span class="text-medium"> Volume: </span>
                            {{ item.blood_product_volume ?? "N/A" }} mL
                          </td>
                        </template>
                        <template v-else>
                          <td>{{ item.art_pressure ?? "-" }}</td>
                          <td>{{ item.blood_flow ?? "-" }}</td>
                          <td>{{ item.line_pressure ?? "-" }}</td>
                          <td>{{ item.flow ?? "-" }}</td>
                          <td>{{ item.pressure ?? "-" }}</td>
                          <td>{{ item.volume_delivered ?? "-" }}</td>
                          <td>{{ item.coronary_sinus_pressure ?? "-" }}</td>
                          <td>{{ item.fio2 ?? "-" }}</td>
                          <td>{{ item.sweep ?? "-" }}</td>
                          <td>{{ item.anesthetic_gas ?? "-" }}</td>
                          <td>{{ item.art_temp ?? "-" }}</td>
                          <td>{{ item.venous_temp ?? "-" }}</td>
                          <td>{{ item.core_temp ?? "-" }}</td>
                          <td>{{ item.act ?? "-" }}</td>
                          <td>{{ item.svo2 ?? "-" }}</td>
                          <td>{{ item.rso2_left ?? "-" }}</td>
                          <td>{{ item.rso2_right ?? "-" }}</td>
                          <template v-if="item.event == 'Cardioplegia'">
                            <td align="left" colspan="11">
                              <span v-html="cardioplegiaEvent(item)" />
                            </td>
                          </template>
                          <template v-else-if="item.event == 'Status'">
                            <td align="left" colspan="11"></td>
                          </template>
                          <template v-else>
                            <td class="highlight">{{ item.ph ?? "-" }}</td>
                            <td class="highlight">{{ item.pco2 ?? "-" }}</td>
                            <td class="highlight">{{ item.po2 ?? "-" }}</td>
                            <td class="highlight">{{ item.hco3 ?? "-" }}</td>
                            <td class="highlight">{{ item.base ?? "-" }}</td>
                            <td class="highlight">{{ item.o2_sat ?? "-" }}</td>
                            <td class="highlight">
                              {{ item.hb ?? "-" }}/{{ item.hct ?? "-" }}
                            </td>
                            <td class="highlight">{{ item.ica ?? "-" }}</td>
                            <td class="highlight">{{ item.kplus ?? "-" }}</td>
                            <td class="highlight">{{ item.naplus ?? "-" }}</td>
                            <td class="highlight">{{ item.glu ?? "-" }}</td>
                          </template>
                        </template>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="28" align="left">
                        <strong>No Data Found.</strong>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
          <div class="monitor-right-bottom">
            <div class="quick-actions">
              <ul class="quick-actions-list">
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedCBP
                        ? open({
                            title: 'Medication',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.cpbMedication',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Medication
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedCBP
                        ? open({
                            title: 'Cooling',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.cpbCooling',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Cooling
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedCBP
                        ? open({
                            title: 'Warming',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.cpbWarning',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Warming
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedCBP
                        ? open({
                            title: '+ Volume',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.cpbPlusVolume',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    + Volume
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedCBP
                        ? open({
                            title: '- Volume',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.cpbMinusVolume',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    - Volume
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedCBP
                        ? open({
                            title: 'Blood Product',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.cpbBloodProduct',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Blood Products
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedCBP
                        ? open({
                            title: 'Comment',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.cpbComment',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Events
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedCBP
                        ? open({
                            title: 'Shift Change',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.shiftChange',
                            callback: (e) => redirectToIndexPage(),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Shift Change
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { Line } from "@antv/g2plot";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  props: [
    "caseReport",
    "cpb",
    "uuid",
    "isCompleted",
    "isAdminLiveChartingPage",
  ],

  emits: ["updateCaseReport", "closeCaseClickEvent"],

  data() {
    return {
      moment,
      byPassLoading: false,
      xClampOnLoading: false,
      cirArrestLoading: false,
      showChart: false,
      cpbChart: null,
      cpbChartConfig: {
        xField: "time",
        yField: "value",
        seriesField: "category",
        // point: {
        //   shape: ({ category }) => {
        //     return category === "Gas fuel" ? "square" : "circle";
        //   },
        //   style: ({ year }) => {
        //     return {
        //       r: Number(year) % 4 ? 0 : 3,
        //     };
        //   },
        // },
      },

      currentDateTime: "",
      currentDateTimeInverval: "",
    };
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.caseReport?.hospital?.timezone ?? this.$constants.timezone;
    },

    hasNotCompletedCBP() {
      return !this.isCompleted || this.isAdminLiveChartingPage;
    },

    onlyActiveRecords() {
      var data = this.cpb?.data
        ? this.cpb.data
            .filter((item) => !item.deleted_at)
            .map((item) => {
              if (!item.employee_id) {
                item.employee_id = this.caseReport.employee_id;
              }
              if (!item.date) {
                item.date = this.$customDate.mdy(this.caseReport.date);
                item.datetime = `${item.date} ${item.time}`;
              }
              return item;
            })
            .sort((a, b) =>
              moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
                moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
              )
                ? 1
                : -1
            )
        : [];

      if (data.length != 0) {
        let firstDate = data[0].date;
        let lastDate = data[data.length - 1].date;
        let range = this.$comman.getDateRange(firstDate, lastDate);
        range.forEach((date, idx) => {
          if (idx != 0) {
            data.push({
              event: "Date change",
              date: date,
              time: "00:00:00",
              datetime: moment(`${date} 00:00:00`, "MM-DD-YYYY HH:mm:ss"),
              comment: `Date: ${date}`,
            });
          }
        });
      }

      return data.sort((a, b) =>
        moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
          moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
        )
          ? 1
          : -1
      );
    },

    hasRecords() {
      return this.onlyActiveRecords.length > 0 ? true : false;
    },

    firstRecord() {
      if (!this.hasRecords) {
        return null;
      }
      let record = this.onlyActiveRecords.filter((e) =>
        ["Status", "Blood Gas", "Cardioplegia"].includes(e.event)
      );
      return record ? record[0] : null;
    },

    lastRecord() {
      if (!this.hasRecords || this.checkTimer("Bypass").stop_time) {
        return null;
      }
      let record = this.onlyActiveRecords.filter(
        (e) =>
          ["Status", "Blood Gas", "Cardioplegia"].includes(e.event) &&
          moment(
            this.checkTimer("Bypass").start_datetime,
            "MM-DD-YYYY HH:mm:ss"
          ).isBefore(moment(e.datetime, "MM-DD-YYYY HH:mm:ss"))
      );
      return record ? record[record.length - 1] : null;
    },

    totalVolumeBalance() {
      return this.onlyActiveRecords
        .filter((e) =>
          ["+ Volume", "- Volume", "Blood Product"].includes(e.event)
        )
        .reduce(
          (pre, curr) => {
            let entries = Object.entries(curr);
            pre = pre.map((item) => {
              let entry = entries.find((e) => e[0] == item.key);
              if (entry && entry[1]) {
                item.sum += entry[1];
              }
              return item;
            });
            return pre;
          },
          [
            {
              name: "Blood Product Volume",
              key: "blood_product_volume",
              sum: 0,
              type: "Blood Product",
            },
            {
              name: "Pre-Bypass Volume",
              key: "pre_bypass_volume",
              sum: 0,
              type: "+ Volume",
            },
            {
              name: "Prime Volume",
              key: "prime_volume",
              sum: 0,
              type: "+ Volume",
            },
            {
              name: "Crystalloid",
              key: "crystalloid",
              sum: 0,
              type: "+ Volume",
            },
            { name: "Colloid", key: "colloid", sum: 0, type: "+ Volume" },
            {
              name: "CPB Cell Saver",
              key: "cpb_cell_saver",
              sum: 0,
              type: "+ Volume",
            },
            {
              name: "Autologous Prime",
              key: "autologous_prime",
              sum: 0,
              type: "- Volume",
            },
            {
              name: "Ultrafiltration",
              key: "ultrafiltration",
              sum: 0,
              type: "- Volume",
            },
            { name: "Urine", key: "urine", sum: 0, type: "- Volume" },
            {
              name: "CPB Volume to Cell Saver",
              key: "cpb_volume_to_cell_saver",
              sum: 0,
              type: "- Volume",
            },
            {
              name: "Residual CPB Volume",
              key: "residual_cpb_volume",
              sum: 0,
              type: "- Volume",
            },
          ]
        );
    },

    computedBalance() {
      return this.totalVolumeBalance.reduce(
        (pre, curr) => (pre += curr.sum),
        0
      );
    },

    computedBloodFlow() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.blood_flow ?? pre),
        "-"
      );
    },

    computedCardiacIndex() {
      let bloodFlow =
        this.computedBloodFlow != "-" ? this.computedBloodFlow : 0;
      if (bloodFlow == 0) {
        return 0;
      }
      if (this.caseReport.data.body_surface_area) {
        return Number(
          this.computedBloodFlow /
            Number(this.caseReport.data.body_surface_area)
        ).toFixed(1);
      }
      return 0;
    },

    computedHCT() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.hct ?? pre),
        "-"
      );
    },

    computedSvo2() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.svo2 ?? pre),
        "-"
      );
    },

    computedLinePressure() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.line_pressure ?? pre),
        "-"
      );
    },

    computedCoreTemp() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.core_temp ?? pre),
        "-"
      );
    },

    computedVenousTemp() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.venous_temp ?? pre),
        "-"
      );
    },

    cpbChartData() {
      let res = this.onlyActiveRecords.reduce((pre, curr) => {
        if (curr.hct) {
          pre.push({
            time: curr.time,
            value: curr.hct,
            category: "HCT",
          });
        }
        if (curr.svo2) {
          pre.push({
            time: curr.time,
            value: curr.svo2,
            category: "SvO2",
          });
        }
        if (curr.venous_temp) {
          pre.push({
            time: curr.time,
            value: curr.venous_temp,
            category: "Temp Venous",
          });
        }
        if (curr.blood_flow) {
          pre.push({
            time: curr.time,
            value: curr.blood_flow,
            category: "Art Flow",
          });
        }
        return pre;
      }, []);
      return res;
    },
  },

  mounted() {
    this.currentDateTime = moment()
      .tz(this.timezone)
      .format("MM-DD-YYYY HH:mm:ss");
    var self = this;
    this.currentDateTimeInverval = setInterval(function () {
      self.currentDateTime = moment()
        .tz(self.timezone)
        .format("MM-DD-YYYY HH:mm:ss");
    }, 1000);
  },

  unmounted() {
    clearInterval(this.currentDateTimeInverval);
  },

  methods: {
    ...mapActions("modal", ["open"]),

    callback() {
      // console.log("callback::");
    },

    handleEditEvent(item) {
      let path = "newCaseReport.drawerForm.";
      let title = `Edit ${item.event}`;
      if (item.event == "Status") {
        path += "cpbStatus";
      } else if (item.event == "Blood Gas") {
        path += "cpbBloodGas";
      } else if (item.event == "Cardioplegia") {
        path += "cpbCardioplegia";
      } else if (item.event == "Cooling") {
        path += "cpbCooling";
      } else if (item.event == "Warming") {
        path += "cpbWarning";
      } else if (item.event == "Medication") {
        path += "cpbMedication";
      } else if (item.event == "+ Volume") {
        path += "cpbPlusVolume";
      } else if (item.event == "- Volume") {
        path += "cpbMinusVolume";
      } else if (item.event == "Blood Product") {
        path += "cpbBloodProduct";
      } else if (item.event == "Comment") {
        path += "cpbComment";
      } else if (["Bypass", "X-Clamp", "Cir. Arrest"].includes(item.event)) {
        path += "cpbTimer";
        title += " Timer";
      }
      this.open({
        title,
        path,
        callback: (e) => this.$emit("updateCaseReport", e),
        record: this.caseReport,
        extra: { record: item, onlyActiveRecords: this.onlyActiveRecords },
      });
    },

    deleteRecord(unique_code) {
      commonService
        .store(this.$constants.caseReportStoreCPBRecordUrl, {
          unique_code,
          uuid: this.uuid,
          delete: true,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.$emit("updateCaseReport", res.data);
        })
        .catch((err) => this.$message.error(err));
    },

    checkTimer(type) {
      return this.onlyActiveRecords
        .filter((e) => e.event == type)
        .reduce((pre, curr) => {
          if (pre.start_time && pre.stop_time) {
            pre = {};
          }
          if (["Start", "On"].includes(curr.note)) {
            pre.start_time = curr.time;
            pre.start_datetime = `${curr.date} ${curr.time}`;
          } else if (["Stop", "Off"].includes(curr.note)) {
            pre.stop_time = curr.time;
            pre.end_datetime = `${curr.date} ${curr.time}`;
          }
          return pre;
        }, {});
    },

    timer(type) {
      let checkTimer = this.checkTimer(type);
      let startDateTime = checkTimer.start_datetime;
      let endDateTime = checkTimer.end_datetime;
      if (this.currentDateTime && startDateTime) {
        let seconds = moment(
          endDateTime ?? this.currentDateTime,
          "MM-DD-YYYY HH:mm:ss"
        ).diff(moment(startDateTime, "MM-DD-YYYY HH:mm:ss"), "seconds");
        return this.$comman.secondsToHms1(seconds);
      }
      return "";
    },

    handleTimerEvent(event, name) {
      this.loadingCallback(name, true);
      let data = { uuid: this.uuid, event };
      let timers = this.checkTimer(event);
      if (timers.start_time && !timers.stop_time) {
        data.note = "Stop";
      } else {
        data.note = "Start";
      }
      if (event == "X-Clamp") {
        data.note = data.note == "Start" ? "On" : "Off";
      }
      data.date = moment().tz(this.timezone).format("MM-DD-YYYY");
      data.time = moment().tz(this.timezone).format("HH:mm:ss");
      data.datetime = `${data.date} ${data.time}`;
      data.employee_id = this.employee.id;
      data.employee_name = this.employee.full_name;

      if (["Stop", "Off"].includes(data.note)) {
        let startTime = moment(timers.start_datetime, "MM-DD-YYYY HH:mm:ss");
        let endTime = moment(data.datetime, "MM-DD-YYYY HH:mm:ss");
        data.duration = endTime.diff(startTime, "seconds");
      }

      commonService
        .store(this.$constants.caseReportStoreCPBRecordUrl, data)
        .then((res) => {
          this.$emit("updateCaseReport", res.data);
        })
        .catch((err) => this.$message.error(err))
        .finally(() => this.loadingCallback(name, false));
    },

    loadingCallback(name, bool) {
      this[name] = bool;
    },

    plusVolumeEvent(item) {
      return [
        { name: "Pre-Bypass Volume", key: "pre_bypass_volume" },
        { name: "Prime Volume", key: "prime_volume" },
        { name: "Crystalloid", key: "crystalloid" },
        { name: "Colloid", key: "colloid" },
        { name: "CPB Cell Saver", key: "cpb_cell_saver" },
      ]
        .reduce((pre, curr) => {
          if (item[curr.key] && !["", null].includes(item[curr.key])) {
            pre.push(
              `<span class="text-medium"> ${curr.name} : </span> ${
                item[curr.key]
              } mL`
            );
          }
          return pre;
        }, [])
        .join(" | ");
    },

    minusVolumeEvent(item) {
      return [
        { name: "Autologous Prime", key: "autologous_prime" },
        { name: "Ultrafiltration", key: "ultrafiltration" },
        { name: "Urine", key: "urine" },
        { name: "CPB Volume to Cell Saver", key: "cpb_volume_to_cell_saver" },
        { name: "Residual CPB Volume", key: "residual_cpb_volume" },
      ]
        .reduce((pre, curr) => {
          if (item[curr.key] && !["", null].includes(item[curr.key])) {
            pre.push(
              `<span class="text-medium"> ${curr.name} : </span> ${
                item[curr.key]
              } mL`
            );
          }
          return pre;
        }, [])
        .join(" | ");
    },

    cardioplegiaEvent(item) {
      let res = [];
      if (item.route.length > 0) {
        res.push(
          `<span class="text-medium"> Route : </span> ${item.route.join(", ")}`
        );
      }
      if (item.radio) {
        res.push(`<span class="text-medium"> Ratio : </span> ${item.radio}`);
        if (item.radio == "Other") {
          res.push(
            `<span class="text-medium"> Other Ratio : </span> ${item.other_radio}`
          );
        }
      }
      if (item.temperature) {
        res.push(
          `<span class="text-medium"> Temperature : </span> ${item.temperature}`
        );
      }
      if (item.coronary_sinus_pressure && item.route.includes("Retrograde")) {
        res.push(
          `<span class="text-medium"> Coronary Sinus Pressure : </span> ${item.coronary_sinus_pressure}`
        );
      }
      return res.join(" | ");
    },

    redirectToIndexPage() {
      this.$router.push({
        name:
          this.$route.name == "admin.case-report-live-charting"
            ? "admin.case-report"
            : "case-report",
      });
    },
  },

  watch: {
    showChart: function (val) {
      if (val) {
        this.cpbChart = new Line("cpbChart", {
          data: this.cpbChartData,
          ...this.cpbChartConfig,
        });
        this.cpbChart.render();
      } else {
        this.cpbChart.destroy();
      }
    },
  },
};
</script>

<style lang="less">
.monitor {
  position: relative;
  display: block;
  background-color: #001529;
  overflow: hidden;

  .monitor-top {
    display: grid;
    grid-auto-flow: column;

    .monitor-message {
      display: block;
      padding: 1em;
      background-color: #002766;

      h3 {
        color: #fff;
        margin-bottom: 0;
      }
    }
  }

  .monitor-middle {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, auto);
    gap: 0px 0px;

    .monitor-left {
      display: flex;
      flex-direction: row;
      padding: 1em;
    }

    .monitor-right {
      display: flex;
      flex-direction: column;
    }
  }

  .timer-actions {
    display: flex;
    flex-direction: column;

    .timer-action-list {
      padding-left: 0;
      list-style-type: none;

      .action-item {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }

        .timer-action {
          display: grid;
          grid-template-columns: repeat(2, 50fr);
          grid-template-rows: repeat(2, 1fr);
          align-items: center;
          align-items: stretch;
          border: none;
          border-radius: 4px;
          font-size: 12px;
          width: 100%;
          padding: 0;
          color: #254000;
          background-color: #d3f261;
          transition: all 300ms ease-in-out;
          cursor: pointer;
          overflow: hidden;

          &.timer-action-active {
            background-color: #ff7875;
            box-shadow: inset 4px 6px 10px rgba(63, 62, 54, 0.5);
          }

          .title {
            align-content: center;
            display: grid;
            grid-area: 1 / 1 / 3 / 2;
            font-size: 14px;
            font-weight: 700;
            border-right: 1px solid #ccc;
          }

          .start {
            grid-area: 1 / 2 / 2 / 3;
            text-align: center;
            padding: 5px;
            background-color: #ffffff;
            border-bottom: 1px solid #ccc;
          }

          .stop {
            grid-area: 2 / 2 / 3 / 3;
            text-align: center;
            padding: 5px;
            background-color: #ffffff;
          }
        }
      }
    }
  }

  .event-actions {
    display: flex;
    flex-direction: column;

    .event-action-list {
      padding-left: 0;
      list-style-type: none;

      .action-item {
        margin-bottom: 1em;

        .description {
          font-weight: normal;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .event-action {
          border-radius: 4px;
          display: grid;
          align-items: center;
          align-items: stretch;
          font-size: 14px;
          font-weight: 700;
          width: 100%;
          padding: 1em;
          color: #030852;
          background-color: #bae0ff;
          border: none;
          cursor: pointer;
          overflow: hidden;

          &.event-action-close {
            color: #ffffff;
            background-color: #cf1322;
          }
        }
      }
    }
  }

  .indicators {
    position: relative;
    display: block;
    padding: 1em 0;
    overflow-x: auto;

    .indicators-list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0;
      margin-bottom: 0;
      list-style-type: none;

      .indicator-item {
        display: flex;
        flex-direction: column;
        margin-right: 1em;
        border-radius: 4px;
        padding: 5px 10px;
        color: #030852;
        background-color: #bae0ff;
        text-align: center;

        &:last-child {
          margin-right: 0;
        }

        span {
          display: block;
          min-width: 100px;
          white-space: nowrap;
        }

        .count {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }

  .event-table {
    display: block;
    width: 100%;
    height: 500px;
    overflow-x: auto;
    padding: 0;

    .event-table-compact {
      position: relative;
      color: #07203c;
      background-color: #ffffff;
      border-collapse: initial;
      border-spacing: 0;
      width: 100%;
      text-align: center;
      border-radius: 4px;
      overflow: scroll;

      thead {
        background-color: #fff;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

        tr {
          th {
            border: 1px solid #ccc;
            min-width: 60px;

            &.highlight {
              background-color: #eee;
            }
          }

          &:nth-child(1) {
            th:nth-child(2) {
              min-width: 100px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            border: 1px solid #ccc;

            &.highlight {
              background-color: #eee;
            }
          }
        }
      }
    }
  }

  .scroller {
    & {
      scrollbar-width: thin;
      scrollbar-color: #243d5c #001529;
    }

    &::-webkit-scrollbar {
      width: 4px;
      width: 4px;
      height: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: #001529;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: #001529;
    }

    &::-webkit-scrollbar-track:active {
      background-color: #001529;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #243d5c;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #243d5c;
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: #243d5c;
    }
  }

  .flow-info {
    .flow-info-table {
      text-align: center;
      width: 100%;
      border-radius: 4px;
      overflow: hidden;

      thead {
        background-color: #bae0ff;

        tr {
          th {
            border: 1px solid #bae0ff;
          }
        }
      }

      tbody {
        background-color: #ffffff;

        tr {
          td {
            border: 1px solid #ccc;
          }
        }
      }
    }
  }

  .quick-actions {
    position: relative;
    display: block;
    padding: 1em 0;
    overflow-x: auto;

    .quick-actions-list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0;
      margin-bottom: 0;
      list-style-type: none;

      .quick-item {
        margin-right: 1em;

        &:last-child {
          margin-right: 0;
        }

        .action {
          border-radius: 4px;
          display: grid;
          align-items: center;
          align-items: stretch;
          font-size: 14px;
          font-weight: 700;
          width: 100%;
          padding: 1em;
          color: #873800;
          background-color: #ffe7ba;
          border: none;
          cursor: pointer;
          overflow: hidden;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .monitor {
    .monitor-middle {
      grid-template-columns: 15% 85%;
      grid-template-rows: 1fr;

      .monitor-left {
        flex-direction: column;
      }
    }
  }
}
</style>

<template>
  <a-divider orientation="left">ECMO Live Charting</a-divider>

  <a-row :gutter="[16, 10]">
    <a-col :span="12">
      <a-row type="flex">
        <a-col flex="35px"> N/A </a-col>
        <a-col flex="35px"><CheckOutlined /></a-col>
        <a-col flex="calc(100% - 70px)">
          <strong>SETUP CHECKLIST</strong>
        </a-col>
      </a-row>

      <template v-for="option in $constants.ecmoSetupChecklist" :key="option">
        <a-row type="flex">
          <a-col flex="35px">
            <i
              v-if="ecmo.checklist?.setup?.includes(option)"
              class="ti ti-checkbox ti-lg"
            />
            <i v-else class="ti ti-square ti-lg"></i>
          </a-col>
          <a-col flex="35px">
            <i
              v-if="ecmo.checklist?.na_setup?.includes(option)"
              class="ti ti-checkbox ti-lg"
            />
            <i v-else class="ti ti-square ti-lg"></i>
          </a-col>
          <a-col flex="calc(100% - 70px)">
            <span v-html="option" />
            <template v-if="option == 'Full tank of O<sub>2</sub>'">
              <span style="text-decoration: underline">
                {{ ecmo?.checklist?.o2 }}
              </span>
            </template>
          </a-col>
        </a-row>
      </template>
    </a-col>

    <a-col :span="12">
      <a-row type="flex">
        <a-col flex="35px"> N/A </a-col>
        <a-col flex="35px"><CheckOutlined /></a-col>
        <a-col flex="calc(100% - 70px)">
          <strong>PRE-PRIME CHECKLIST</strong>
        </a-col>
      </a-row>

      <template
        v-for="option in $constants.ecmoPrePrimeChecklist"
        :key="option"
      >
        <a-row type="flex">
          <a-col flex="35px">
            <i
              v-if="ecmo.checklist?.pre_prime?.includes(option)"
              class="ti ti-checkbox ti-lg"
            />
            <i v-else class="ti ti-square ti-lg"></i>
          </a-col>
          <a-col flex="35px">
            <i
              v-if="ecmo.checklist?.na_pre_prime?.includes(option)"
              class="ti ti-checkbox ti-lg"
            />
            <i v-else class="ti ti-square ti-lg"></i>
          </a-col>
          <a-col flex="calc(100% - 70px)">
            <span v-html="option" />
          </a-col>
        </a-row>
      </template>

      <a-row type="flex" class="mt-sm-1">
        <a-col flex="35px"> N/A </a-col>
        <a-col flex="35px"><CheckOutlined /></a-col>
        <a-col flex="calc(100% - 70px)">
          <strong>POST-PRIME CHECKLIST</strong>
        </a-col>
      </a-row>

      <template
        v-for="option in $constants.ecmoPostPrimeChecklist"
        :key="option"
      >
        <a-row type="flex">
          <a-col flex="35px">
            <i
              v-if="ecmo.checklist?.post_prime?.includes(option)"
              class="ti ti-checkbox ti-lg"
            />
            <i v-else class="ti ti-square ti-lg"></i>
          </a-col>
          <a-col flex="35px">
            <i
              v-if="ecmo.checklist?.na_post_prime?.includes(option)"
              class="ti ti-checkbox ti-lg"
            />
            <i v-else class="ti ti-square ti-lg"></i>
          </a-col>
          <a-col flex="calc(100% - 70px)">
            <span v-html="option" />
          </a-col>
        </a-row>
      </template>
    </a-col>

    <a-col :span="24">
      <a-descriptions
        size="small"
        bordered
        :labelStyle="{ color: '#666666', width: '150px' }"
        :column="{ sm: 1 }"
      >
        <a-descriptions-item label="Notes:">
          {{ ecmo.checklist?.notes ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Signature:">
          <img :width="200" :src="ecmo.checklist?.sign" />
        </a-descriptions-item>
        <a-descriptions-item label="DateTime:">
          {{ ecmo.checklist?.submitted_at ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>
    </a-col>
  </a-row>

  <a-table
    class="mt-sm-1"
    :rowKey="(_, index) => index"
    :columns="ecmoTablecolumns"
    :data-source="ecmoEvents"
    bordered
    size="small"
    :pagination="false"
    :scroll="{ x: true, y: 0 }"
  >
    <template #spo2CustomTitle> SpO<sub>2</sub> </template>
    <template #fio2CustomTitle> FiO<sub>2</sub> (%) </template>
    <template #phCustomTitle> <sub>p</sub>H </template>
    <template #pco2CustomTitle> pCO<sub>2</sub> (mmHg) </template>
    <template #po2CustomTitle> pO<sub>2</sub> (mmHg) </template>
    <template #hco3CustomTitle> HCO<sub>3</sub> (mmol/l) </template>
    <template #sao2CustomTitle> SaO<sub>2</sub> (%) </template>
    <template #svo2CustomTitle> SVO<sub>2</sub> (%) </template>
    <template #kplusCustomTitle> K<sub>+</sub> </template>
    <template #ventilatorFio2CustomTitle> Ventilator FiO<sub>2</sub> </template>
  </a-table>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { h } from "vue";

export default {
  name: "ecmo-live-charting",
  props: ["ecmo"],

  components: { CheckOutlined },

  data() {
    return {
      ecmoTablecolumns: [
        {
          title: "Time",
          dataIndex: "time",
          key: "time",
          fixed: "left",
          customRender: this.ecmoColspanRender,
        },
        {
          title: "Vital Signs",
          children: [
            {
              title: "Arterial BP (mmHg)",
              dataIndex: "arterial_bp",
              key: "arterial_bp",
              customRender: this.ecmoColspanRender,
            },
            {
              title: "CVP/RA (mmHG)",
              dataIndex: "cvp_pa",
              key: "cvp_pa",
              customRender: this.ecmoColspanRender,
            },
            {
              title: "HR (BPM)",
              dataIndex: "hr",
              key: "hr",
              customRender: this.ecmoColspanRender,
            },
            {
              dataIndex: "spo2",
              key: "spo2",
              slots: {
                title: "spo2CustomTitle",
              },
              customRender: this.ecmoColspanRender,
            },
          ],
        },
        {
          title: "Pump",
          children: [
            {
              title: "Pump Flow (L/min)",
              dataIndex: "pump_flow",
              key: "pump_flow",
              customRender: this.ecmoColspanRender,
            },
            {
              title: "Pump RPM",
              dataIndex: "pump_rpm",
              key: "pump_rpm",
              customRender: this.ecmoColspanRender,
            },
            {
              title: "Gas Flow (L/min)",
              dataIndex: "gas_flow",
              key: "gas_flow",
              customRender: this.ecmoColspanRender,
            },
            {
              dataIndex: "fio2",
              key: "fio2",
              slots: {
                title: "fio2CustomTitle",
              },
              customRender: this.ecmoColspanRender,
            },
            {
              title: "Pre Oxy (mmHg)",
              dataIndex: "pre_oxy",
              key: "pre_oxy",
              customRender: this.ecmoColspanRender,
            },
            {
              title: "Post Oxy (mmHg)",
              dataIndex: "post_oxy",
              key: "post_oxy",
              customRender: this.ecmoColspanRender,
            },
            {
              title: "Δ P (mmHg)",
              dataIndex: "delta_oxy",
              key: "delta_oxy",
              customRender: this.ecmoColspanRender,
            },
          ],
        },
        {
          title: "Temperature (°C)",
          children: [
            {
              title: "Core",
              dataIndex: "core",
              key: "core",
              customRender: this.ecmoColspanRender,
            },
            {
              title: "Post-Exchanger",
              dataIndex: "post_exchanger",
              key: "post_exchanger",
              customRender: this.ecmoColspanRender,
            },
            {
              title: "Water Bath",
              dataIndex: "water_bath",
              key: "water_bath",
              customRender: this.ecmoColspanRender,
            },
          ],
        },
        {
          title: "Blood Gas",
          children: [
            {
              dataIndex: "ph",
              key: "ph",
              slots: {
                title: "phCustomTitle",
              },
              customRender: this.ecmoColspanRender,
            },
            {
              dataIndex: "pco2",
              key: "pco2",
              slots: {
                title: "pco2CustomTitle",
              },
              customRender: this.ecmoColspanRender,
            },
            {
              dataIndex: "po2",
              key: "po2",
              slots: {
                title: "po2CustomTitle",
              },
              customRender: this.ecmoColspanRender,
            },
            {
              title: "Temp (°C)",
              dataIndex: "temp",
              key: "temp",
              customRender: this.ecmoColspanRender,
            },
            {
              dataIndex: "hco3",
              key: "hco3",
              slots: {
                title: "hco3CustomTitle",
              },
              customRender: this.ecmoColspanRender,
            },
            {
              title: "B/E",
              dataIndex: "be",
              key: "be",
              customRender: this.ecmoColspanRender,
            },
            {
              title: "Hgb/HCT",
              dataIndex: "hgb_hct",
              key: "hgb_hct",
              customRender: this.ecmoColspanRender,
            },
            {
              title: "SaO2 (%)",
              dataIndex: "sao2",
              key: "sao2",
              slots: {
                title: "sao2CustomTitle",
              },
              customRender: this.ecmoColspanRender,
            },
            {
              title: "SVO2 (%)",
              dataIndex: "svo2",
              key: "svo2",
              slots: {
                title: "svo2CustomTitle",
              },
              customRender: this.ecmoColspanRender,
            },
            {
              dataIndex: "kplus",
              key: "kplus",
              slots: {
                title: "kplusCustomTitle",
              },
              customRender: this.ecmoColspanRender,
            },
            {
              dataIndex: "ventilator",
              key: "ventilator",
              slots: {
                title: "ventilatorFio2CustomTitle",
              },
              customRender: this.ecmoColspanRender,
            },
          ],
        },
        {
          title: "Coagulation",
          children: [
            {
              title: "PTT (sec)",
              dataIndex: "ptt",
              key: "ptt",
              customRender: this.ecmoColspanRender,
            },
            {
              title: "Heparin (units/HR)",
              dataIndex: "heparin",
              key: "heparin",
              customRender: this.ecmoColspanRender,
            },
          ],
        },
      ],
    };
  },

  computed: {
    ecmoEvents() {
      if (this.ecmo?.data) {
        let data = this.ecmo.data
          .filter((e) => !e.deleted_at)
          .sort((a, b) =>
            moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
              moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
            )
              ? 1
              : -1
          );

        if (data.length != 0) {
          let firstDate = data[0].date;
          let lastDate = data[data.length - 1].date;
          let range = this.$comman.getDateRange(firstDate, lastDate);
          range.forEach((date, idx) => {
            if (idx != 0) {
              data.push({
                event: "Date change",
                date: date,
                time: "00:00:00",
                datetime: moment(`${date} 00:00:00`, "MM-DD-YYYY HH:mm:ss"),
                comment: `Date: ${date}`,
              });
            }
          });
        }

        return data.sort((a, b) =>
          moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
            moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
          )
            ? 1
            : -1
        );
      }
      return [];
    },

    ecmoTime() {
      if (this.ecmo?.timers?.ecmo[0]) {
        let timers = this.ecmo.timers.ecmo[0];
        let startTime = moment(timers.start_time, "MM-DD-YYYY HH:mm:ss");
        let stopTime = moment(timers.stop_time, "MM-DD-YYYY HH:mm:ss");
        let duration = stopTime.diff(startTime, "seconds");
        return moment.utc(duration * 1000).format("HH:mm:ss");
      }
      return "";
    },
  },

  methods: {
    ecmoColspanRender(data) {
      if (["ECMO", "Shift Change", "Comment"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else if (data.column.key == "arterial_bp") {
          let note = "";
          if (["ECMO", "Shift Change"].includes(data.record.event)) {
            note = data?.record?.note ?? "";
          } else {
            note = data?.record?.comment ?? "";
          }
          if (note == "ECMO End") {
            note += ` (${this.ecmoTime})`;
          }
          if (["ECMO", "Shift Change"].includes(data.record.event)) {
            note += ` [Date: ${data.record?.date ?? ""}]`;
          }
          return {
            children: note,
            props: { colSpan: 27 },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else if (["Date change"].includes(data.record.event)) {
        if (data.column.key == "time") {
          return {
            children: h("div", {
              innerHTML: data.record.comment,
            }),
            props: {
              colSpan: 28,
              style: { backgroundColor: "#eee", borderColor: "#fff" },
            },
          };
        } else {
          return {
            props: { colSpan: 0 },
          };
        }
      } else {
        if (data.column.key == "time") {
          return data.record.time.slice(0, 5);
        } else if (data.column.key == "hgb_hct") {
          return `${data.record.hb ?? "-"} / ${data.record.hct ?? "-"}`;
        }
        return data.text ?? "-";
      }
    },
  },
};
</script>

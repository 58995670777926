<template>
  <a-row :gutter="[20, 0]">
    <a-col :span="6">
      <a-typography-text strong>
        Type of Preservation Solution
      </a-typography-text>
    </a-col>

    <a-col :span="6">
      <a-typography-text strong> Cannula Location </a-typography-text>
    </a-col>

    <a-col :span="6">
      <a-typography-text strong>Solution Volume</a-typography-text>
    </a-col>

    <a-col :span="6">
      <a-typography-text strong>Delivery Pressure</a-typography-text>
    </a-col>

    <template v-for="(ps, key) in model.ps" :key="key">
      <a-col :span="24">
        <a-typography-text strong>{{ key }}</a-typography-text>
      </a-col>

      <a-col :span="6">
        <a-form-item
          :name="['ps', key, 'removed', 'type_of_perservation_solution']"
          :rules="{
            message: 'This field is required!',
            required: true,
          }"
        >
          <a-select
            v-model:value="ps.removed.type_of_perservation_solution"
            placeholder="Select any one..."
            show-search
            allowClear
            optionFilterProp="label"
            :options="$constants.perservation_solution_types"
          />
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item
          :name="['ps', key, 'removed', 'cannula_location']"
          :rules="{
            message: 'This field is required!',
            required: true,
          }"
        >
          <a-input
            v-model:value="ps.removed.cannula_location"
            placeholder="Enter cannula location here..."
          />
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item
          :name="['ps', key, 'removed', 'solution_volumn']"
          :rules="{
            message: 'This field is required!',
            type: 'integer',
            required: true,
          }"
        >
          <a-input-number
            type="number"
            v-model:value="ps.removed.solution_volumn"
            placeholder="Enter solution volume here..."
          />
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item
          :name="['ps', key, 'removed', 'delivery_pressure']"
          :rules="{
            message: 'This field is required!',
            type: 'integer',
            required: true,
          }"
        >
          <a-input-number
            type="number"
            v-model:value="ps.removed.delivery_pressure"
            placeholder="Enter delivery pressure here..."
          />
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item
          :name="['ps', key, 'placed', 'type_of_perservation_solution']"
        >
          <a-select
            v-model:value="ps.placed.type_of_perservation_solution"
            placeholder="Select any one..."
            show-search
            allowClear
            optionFilterProp="label"
            :options="$constants.perservation_solution_types"
          />
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item
          :name="['ps', key, 'placed', 'cannula_location']"
          :rules="{
            message: 'This field is required!',
            required: ps.placed.type_of_perservation_solution,
          }"
        >
          <a-input
            v-model:value="ps.placed.cannula_location"
            placeholder="Enter cannula location here..."
          />
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item
          :name="['ps', key, 'placed', 'solution_volumn']"
          :rules="{
            message: 'This field is required!',
            type: 'integer',
            required: ps.placed.type_of_perservation_solution,
          }"
        >
          <a-input-number
            type="number"
            v-model:value="ps.placed.solution_volumn"
            placeholder="Enter solution volume here..."
          />
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item
          :name="['ps', key, 'placed', 'delivery_pressure']"
          :rules="{
            message: 'This field is required!',
            type: 'integer',
            required: ps.placed.type_of_perservation_solution,
          }"
        >
          <a-input-number
            type="number"
            v-model:value="ps.placed.delivery_pressure"
            placeholder="Enter delivery pressure here..."
          />
        </a-form-item>
      </a-col>
    </template>
  </a-row>
</template>

<script>
export default {
  name: "case-report-perservation-solution-step",
  inheritAttrs: false,
  props: ["step", "model"],
  emits: ["validate"],

  data() {
    return {
      //
    };
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>

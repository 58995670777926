<template>
  <a-form
    :model="model"
    :rules="rules"
    layout="vertical"
    @finish="handleFinish"
  >
    <a-form-item label="Emails" ref="emails" name="emails">
      <a-select
        v-model:value="model.emails"
        mode="tags"
        style="width: 100%"
        placeholder="Enter emails here..."
        @change="handleChange"
      >
      </a-select>
    </a-form-item>

    <a-form-item ref="subject" label="Subject" name="subject">
      <a-input
        v-model:value="model.subject"
        placeholder="Enter subject here..."
      />
    </a-form-item>

    <a-form-item ref="content" label="Body" name="content">
      <ckeditor
        :editor="editor"
        v-model="model.content"
        :config="editorConfig"
      />
    </a-form-item>

    <a-space direction="vertical" :size="15">
      <a-button :loading="renderingPdf" type="link" @click="renderPdf">
        <a-space>
          <i class="ti ti-paperclip"></i>
          Attachment
        </a-space>
      </a-button>

      <a-space>
        <a-button type="primary" htmlType="submit" :loading="loading">
          <a-space>
            <i v-if="!loading" class="ti ti-send ti-lg"></i>
            Send Email
          </a-space>
        </a-button>

        <a-button
          v-if="!loading"
          type="primary"
          danger
          htmlType="reset"
          @click="$emit('close')"
        >
          <a-space> <i class="ti ti-x ti-lg"></i> Cancel </a-space>
        </a-button>
      </a-space>
    </a-space>
  </a-form>
</template>

<script>
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import signature from "../../../assets/louis_signature";
import { commonService } from "../../../services";

export default {
  name: "hospital-qi-report-notification-mail-from",

  props: {
    record: {
      required: true,
      default: null,
    },
  },

  emits: ["close"],

  data() {
    return {
      loading: false,
      renderingPdf: false,
      model: {
        id: null,
        emails: [],
        subject: "",
        content: "",
      },
      rules: {
        emails: [
          {
            required: true,
            message: "This field is required!",
            type: "array",
          },
        ],
        subject: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        content: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "",
      },
    };
  },

  mounted() {
    this.updateModelData();
  },

  methods: {
    updateModelData() {
      this.model.id = this.record.id;
      this.model.emails = this.record.emails;
      this.model.subject = `${this.$customDate.mdy(
        this.record.start_date
      )} to ${this.$customDate.mdy(
        this.record.end_date
      )} ${this.$comman.getValueFromObject(
        this.record,
        "type",
        this.$constants.qi_report_notification_types,
        "label"
      )} Quality Improvement (QI) Report - Keystone Perfusion Services`;
      this.model.content = `Hi ${this.record.hospital.name} Team,
    <br/><br/>
    We are pleased to share the attached ${this.$comman.getValueFromObject(
      this.record,
      "type",
      this.$constants.qi_report_notification_types,
      "label"
    )} Quality Improvement (QI) Report for your review. These quality reports are automatically generated and emailed to ensure timely delivery and consistent quality oversight. Each report undergoes a thorough review for accuracy and completeness to identify and address any errors or omissions prior to submission. <br /><br />
    This report provides insights and data to support our shared commitment to delivering the highest standards in patient care and continuous improvement in outcomes. <br /><br />
    If you have any questions or need further details, please feel free to reach out. We’re here to assist. Thank you for your continued partnership. <br /><br />
    Warm regards,<br/>
    Keystone Perfusion Services, PC`;
    },

    handleFinish() {
      this.loading = true;

      commonService
        .store(
          this.$constants.hospitalQiReportNotificationSendEmailUrl,
          this.model
        )
        .then((res) => {
          this.$message.success(res.message);
          this.$emit("close", true);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleChange(val) {
      if (val.length > 0) {
        this.rules.emails[0].fields = [];
        for (let index = 0; index < val.length; index++) {
          this.rules.emails[0].fields.push({
            type: "email",
            message: `Invalid email!`,
          });
        }
      } else {
        this.rules.emails[0].fields = [];
      }
    },

    formData() {
      const start_date = this.$customDate.ymd(this.record.start_date);
      const end_date = this.$customDate.ymd(this.record.end_date);
      return {
        start_date,
        end_date,
        comments:
          "This QI report is generated from hospital QI report notification page.",
        signature: signature,
        signatured_at: this.$customDate.mdyhis(this.$comman.momentTz()),
        hospital_id: this.record.hospital_id,
      };
    },

    renderPdf() {
      this.renderingPdf = true;

      let url = this.$comman.getValueFromObject(
        this.record,
        "type",
        this.$constants.qi_report_notification_types,
        "api"
      );

      commonService
        .renderFile(url, this.formData(this.record), "application/pdf;base64")
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.renderingPdf = false;
        });
    },
  },

  watch: {
    record: {
      handler: function (newValue) {
        if (newValue != null) {
          this.updateModelData();
        }
      },
      deep: true,
    },
  },
};
</script>

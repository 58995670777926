<template>
    <a-layout-content>
      <page-header>
        <template #title> Auto QI Reports </template>
  
        <template #buttons>
          <!--  -->
        </template>
      </page-header>
  
      <a-card
        :tabList="tabList"
        :activeTabKey="activeTabKey"
        @tabChange="(key) => (activeTabKey = key)"
        size="small"
      >
        <template #customRender="item">
          {{ item.text }} ({{ item.counts }})
        </template>
  
        <a-table
          :columns="columns"
          :rowKey="(record, index) => record.id"
          :dataSource="dataSource"
          :pagination="pagination" 
          :loading="datatableLoading"
          @change="handleTableChange"
          @refresh="refresh"
          size="small"
          :scroll="scroll"
        >
          <template #filterDropdown="filterDropdown">
            <xFilterInputSearchDropdown
              :filterDropdown="filterDropdown"
              @handleSearch="handleDatatableSearch"
              @handleReset="handleDatatableReset"
            />
          </template>
  
          <template #filterIcon="filterIcon">
            <xFilterIcon :filterIcon="filterIcon" />
          </template>
  
          <template #action="{ record }">
            <a-space :size="1">
              <a-tooltip title="Preview PDF">
                <a-button type="link" size="small" @click="downloadPdf(record)">
                  <i class="ti ti-printer ti-lg"></i>
                </a-button>
              </a-tooltip>
  
              <a-tooltip title="Send Mail">
                <a-button
                  type="link"
                  size="small"
                  @click="showMailFormModel(record)"
                >
                  <i class="ti ti-mail ti-lg"></i>
                </a-button>
              </a-tooltip>
  
              <a-popconfirm
                title="Are you sure you want to delete this notification?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="deleteNotification(record)"
              >
                <a-tooltip title="Delete">
                  <a-button size="small" type="link" danger>
                    <i class="ti ti-trash ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </a-space>
          </template>
        </a-table>
      </a-card>
  
      <a-modal
        v-model:visible="showMailForm"
        :title="mailFormModelTitle"
        width="1000px"
        :footer="null"
        :closable="false"
        :maskClosable="false"
        style="top: 20px"
      >
        <mail-form :record="mailFormRecord" @close="closeMailFormModel" />
      </a-modal>
    </a-layout-content>
  </template>
  
  <script>
  import { InfoCircleOutlined } from "@ant-design/icons-vue";
  import moment from "moment";
  import signature from "../../../assets/louis_signature";
  import datatableMixins from "../../../mixins/datatableMixins";
  import { commonService } from "../../../services";
  import mailForm from "./mailForm.vue";
  
  export default {
    components: {
      InfoCircleOutlined,
      mailForm,
    },
  
    mixins: [datatableMixins],
  
    data() {
      return {
        quickbookGetAccountLoading: false,
        datatableUrl: this.$constants.hospitalQiReportNotificationUrl,
        columns: [
          {
            title: "Hospital",
            dataIndex: "hospital_name",
            key: "hospital_name",
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon",
            },
            customRender: ({ record }) => record?.hospital?.name ?? "N/A",
            defaultFilteredValue: this.$route.query?.hospital_name
              ? [this.$route.query?.hospital_name]
              : undefined,
          },
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
            customRender: ({ record }) => {
              return this.$comman.getValueFromObject(
                record,
                "type",
                this.$constants.qi_report_notification_types,
                "label"
              );
            },
          },
          {
            title: "Start Date",
            dataIndex: "start_date",
            key: "start_date",
            customRender: ({ text }) => this.$customDate.mdy(text),
            defaultFilteredValue: this.$route.query?.start_date
              ? [this.$route.query?.start_date]
              : undefined,
          },
          {
            title: "End Date",
            dataIndex: "end_date",
            key: "end_date",
            customRender: ({ text }) => this.$customDate.mdy(text),
            defaultFilteredValue: this.$route.query?.end_date
              ? [this.$route.query?.end_date]
              : undefined,
          },
          {
            title: "Frequency",
            dataIndex: "frequency",
            key: "frequency",
            customRender: ({ record }) => {
              return this.$comman.getValueFromObject(
                record,
                "frequency",
                this.$constants.qi_report_frequency_types,
                "label"
              );
            },
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            fixed: "right",
            slots: { customRender: "action" },
          },
        ],
        activeTabKey: this.$route.query?.status ?? "0",
        tabList: this.$constants.hospital_qi_report_notification_statuses.map(
          (item) => ({
            key: item.value.toString(),
            counts: 0,
            text: item.text,
            status: item.value,
            slots: { tab: "customRender" },
          })
        ),
        defaultFilterParams: {
          hospital_name: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
  
        showMailForm: false,
        mailFormRecord: null,
      };
    },
  
    computed: {
      mailFormModelTitle() {
        return this.mailFormRecord
          ? `${
              this.mailFormRecord.hospital.name
            } | ${this.$comman.getValueFromObject(
              this.mailFormRecord,
              "type",
              this.$constants.qi_report_notification_types,
              "label"
            )} QI Report`
          : "";
      },
    },
  
    methods: {
      formData(record) {
        const start_date = this.$customDate.ymd(record.start_date);
        const end_date = this.$customDate.ymd(record.end_date);
        return {
          start_date,
          end_date,
          comments:
            "This QI report is generated from hospital QI report notification page.",
          signature: signature,
          signatured_at: this.$customDate.mdyhis(this.$comman.momentTz()),
          hospital_id: record.hospital_id,
        };
      },
  
      dateString(record) {
        return (
          moment(record.start_date).clone().format("MMDDYY") +
          "-" +
          moment(record.end_date).clone().format("MMDDYY")
        );
      },
  
      downloadPdf(record) {
        this.datatableLoading = true;
  
        let url = this.$comman.getValueFromObject(
          record,
          "type",
          this.$constants.qi_report_notification_types,
          "api"
        );
  
        commonService
          .renderFile(url, this.formData(record), "application/pdf;base64")
          .then((res) => {
            // this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.datatableLoading = false;
          });
      },
  
      showMailFormModel(rec) {
        this.showMailForm = true;
        this.mailFormRecord = rec;
      },
  
      closeMailFormModel(refresh = false) {
        this.showMailForm = false;
        this.mailFormRecord = null;
        if (refresh) {
          this.refresh();
        }
      },
  
      deleteNotification(record) {
        this.datatableLoading = true;
  
        commonService
          .store(this.$constants.hospitalQiReportNotificationDeleteUrl, {
            id: record.id,
          })
          .then((res) => {
            this.$message.success(res.message);
            this.refresh();
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.datatableLoading = false;
          });
      },
    },
  };
  </script>
  